// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import React, { useState, useEffect } from "react";

function ImageSlider({ imageUrls, slideInterval = 3000 }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isHovered) {
        slideNext();
      }
    }, slideInterval);

    return () => {
      clearInterval(timer);
    };
  }, [activeIndex, slideInterval, isHovered]);

  const slideNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const slideToIndex = (index) => {
    setActiveIndex(index);
  };

  return (
    <div
      className="relative h-full w-full overflow-hidden border bg-[#ffffff] py-1 shadow flex items-center justify-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className="flex transition-transform ease-in-out duration-500"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {imageUrls?.map((imageUrl, index) => (
          <div
            key={index}
            className="w-full h-full flex-shrink-0 flex justify-center px-[0.5px] pt-[0.5px] mb-4"
          >
            <img
              src={imageUrl}
              alt={`Image ${index}`}
              className="object-cover "
            />
          </div>
        ))}
      </div>
      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2 ">
        {imageUrls.map((_, index) => (
          <button
            key={index}
            className={`w-[6px] h-[6px] rounded-full ${
              index === activeIndex ? "bg-[#5C276E]" : "bg-[#e69aff]"
            }`}
            onClick={() => slideToIndex(index)}
          ></button>
        ))}
      </div>
    </div>
  );
}

export default ImageSlider;
