// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
const clearStorageExceptEmailAndPassword = () => {
  const { email, password, checkbox, isLoggedOut, ...rest } = localStorage;
  Object.keys(rest).forEach((key) => {
    localStorage.removeItem(key);
  });
  sessionStorage.clear();
};

export { clearStorageExceptEmailAndPassword };
