// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { FiExternalLink } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveDashboard,
  showVideoPopup,
  showSalesPopUp,
  hideSalesPopUp
} from "../../store/auth/action";
import { getSession } from "../../utils/sessionstore";
import { userLogsAPI } from "../../services/session.service";
import CustomContextMenu2 from "./CustomContextMenu2";
import { useRef } from "react";
import { IoMdLock } from "react-icons/io";
import { Tooltip } from "antd";
const NewDashboardBox = ({ dashboard, afterLandDashboard }) => {
  const { clientID } = useParams();
  const currentURL = window.location.origin;
  const [tooltipVisibility, setTooltipVisible] = useState(false);
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const dispatch = useDispatch();
  const {
    newTabClickObject,
    userDetails,
    clientname,
    rightClickOptionPosition,
  } = useSelector((state) => ({
    newTabClickObject: state.newTabClickObject,
    clientname: state.clientname,
    userDetails: state.userDetails,
    rightClickOptionPosition: state.rightClickOptionPosition,
  }));
  const contextMenuRef2 = useRef(null);
  const [contextMenuVisible2, setContextMenuVisible2] = useState(false);
  const sessionId = getSession("session_id");

  async function callUserLogAPI(
    place,
    dashboardName,
    standardName,
    dashboardId,
    clickType
  ) {
    try {
      const logresponse = userLogsAPI(
        place,
        standardName,
        userDetails,
        clientname,
        dashboardName,
        clickType,
        getSession("client_id"),
        dashboardId
      );
      // console.log(logresponse, "banner click");
    } catch (err) {
      console.log(err.message);
    }
  }

  async function landDashboard(activedashboard) {
    //disabled dashboards with no sales content
    if (
      activedashboard?.url === "Greyed" ||
      (activedashboard?.url === "Disabled" && !activedashboard?.salesContentId)
    ) {
      callUserLogAPI(
        "SIDEBAR_DASHBOARD",
        activedashboard?.label,
        activedashboard?.standarddashboardId?.standardName,
        activedashboard?._id,
        "Disabled"
      );
      return;
    }

    //Open in new tab icon dashboards(In insights)
    if (activedashboard?.type === "Open in new tab") {
      callUserLogAPI(
        "SIDEBAR_DASHBOARD",
        activedashboard?.label,
        activedashboard?.standarddashboardId?.standardName,
        activedashboard?._id,
        "Viewed"
      );
      window.open(dashboard?.url, "_blank");
      return;
    }

    //Open in new tab icon dashboards(Datacube)
    if (activedashboard?.type === "Data Cube") {
      const cubeUrl = activedashboard?.url.split("=")[0];
      window.open(cubeUrl + "=" + userDetails?.email);
      callUserLogAPI(
        "SIDEBAR_DASHBOARD",
        activedashboard?.label,
        activedashboard?.standarddashboardId?.standardName,
        activedashboard?._id,
        "Viewed"
      );
      return;
    }

    //Below conditions will first close the popup first
    dispatch(hideSalesPopUp());

    //Below conditions will first set the active dashboard
    dispatch(setActiveDashboard(activedashboard));

    //Videos recording
    if (activedashboard?.type == "video") {
      callUserLogAPI(
        "SIDEBAR_DASHBOARD",
        activedashboard?.label,
        activedashboard?.standarddashboardId?.standardName,
        activedashboard?._id,
        "Viewed"
      );
      dispatch(showVideoPopup());
      return;
    }

    //Disabled dashboard with salescontentId
    if (activedashboard?.url === "Disabled") {
      dispatch(showSalesPopUp("Locked icon on the side panel"));
      callUserLogAPI(
        "SIDEBAR_DASHBOARD",
        activedashboard?.label,
        activedashboard?.standarddashboardId?.standardName,
        activedashboard?._id,
        "Disabled"
      );
      afterLandDashboard();
      return;
    }
    
    //If dashboard is already open
    if(pathname === `/${clientID}/dashboard/${activedashboard?._id}/SIDEBAR_DASHBOARD` 
      || pathname === `/dashboard/${activedashboard?._id}/SIDEBAR_DASHBOARD`){
        return
    }
   
    callUserLogAPI("SIDEBAR_DASHBOARD", activedashboard?.label,  activedashboard?.standarddashboardId?.standardName, activedashboard?._id, "Viewed");
    //Dashboard directly open
    if (userDetails?.permissions === "HWAI_ADMIN" && clientID !== undefined) {
      navigate(`/${clientID}/dashboard/${activedashboard?._id}/SIDEBAR_DASHBOARD`);
    } else {
      navigate(`/dashboard/${activedashboard?._id}/SIDEBAR_DASHBOARD`);
    }
    afterLandDashboard();
  }

  const handleTooltipClick = () => {
    //Close right click menu
    setContextMenuVisible2(false);

    callUserLogAPI(
      "NEWTAB_SIDEBAR_DASHBOARD",
      dashboard?.label,
      dashboard?.standarddashboardId?.standardName,
      dashboard?._id,
      "Viewed"
    );
    //If dashboard is of open in new tab type
    if (dashboard?.type === "Open in new tab") {
      window.open(dashboard?.url, "_blank");
      afterLandDashboard();
      return;
    }

    if (dashboard?.type === "Data Cube") {
      const cubeUrl = dashboard?.url.split("=")[0];
      window.open(cubeUrl + "=" + userDetails?.email, "_blank");
      afterLandDashboard();
      return;
    }

    //Open dashboard in new tab
    let newurl;
    if (userDetails?.permissions === "HWAI_ADMIN" && clientID !== undefined) {
      newurl =
        currentURL +
        "/" +
        clientID +
        "/dashboard/" +
        dashboard?._id +
        "/NEWTAB_SIDEBAR_DASHBOARD";
    } else {
      // console.log(currentURL, "curren url from new tab sdebar");
      newurl =
        currentURL +
        "/dashboard/" +
        dashboard?._id +
        "/NEWTAB_SIDEBAR_DASHBOARD";
    }
    afterLandDashboard();
    window.open(newurl, "_blank");
  };

  const [rcPosition, setRcPosition] = useState({});

  const handleContextMenu = (e) => {
    setRcPosition({ x: e.clientX, y: e.clientY });
    e.preventDefault();
    setContextMenuVisible2(true);
  };

  const handleClickOutside = (event) => {
    if (parentref.current && !parentref.current.contains(event.target)) {
      setContextMenuVisible2(false);
    }
  };

  function replaceImageSrc(originalSrc) {
    // Use the replace method to replace "_h-16." with "_h-13-dark."
    if (originalSrc) {
      var newSrc = originalSrc.replace("_h-16.", "_h-13-dark.");
      var newSrc2 = newSrc.replace("tags", "images");

      return newSrc2;
    }
    return originalSrc;
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("contextmenu", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.addEventListener("contextmenu", handleClickOutside);
    };
  }, []);
  const parentref = useRef(null);

  return (
      <div
        onMouseLeave={()=>setContextMenuVisible2(false)}  
        ref={parentref}
        onContextMenu={handleContextMenu}
        key={dashboard?._id}
        className={`active:text-[#ffffff] sidebarcomp flex flex-nowrap items-center text-[#DDDDDC] Roboto text-[10px] hover:text-[#ffffff]  pl-1`}
      >
          {contextMenuVisible2 && dashboard?.type != "video" && dashboard?.url != "Disabled" && dashboard?.url != "Greyed" && (
              <div>
                <CustomContextMenu2
                  position={rcPosition}
                  label="Open in New Tab"
                  func={handleTooltipClick}
                />
              </div>
          )}
          <Tooltip placement="right" color="black" overlayStyle={{ maxWidth: '360px' }} 
            title={(dashboard?.hoverText?.trim() && dashboard?.url?.trim() === "Greyed") ? <div className="px-[10px] text-[11px] text-[#E8EAED]">{dashboard?.hoverText?.trim()}</div>:""}  >
            <div
              onClick={() => {landDashboard(dashboard);}}
              className={`Roboto text-[12px] ${dashboard?.url?.trim() ==="Greyed" ? "text-[#81807E] cursor-default" : "text-[#DDDDDC] cursor-pointer" }`}
            >
              {dashboard?.label}
            </div>
          </Tooltip>

          {dashboard?.url?.trim() === "Disabled" ? (<IoMdLock className="text-yellow-500 mx-1 mb-1 cursor-pointer" />) : (<></>)}

          {dashboard?.type?.trim() === "Open in new tab" ? ( <FiExternalLink className="text-[#fff] mx-1 mb-1 cursor-pointer" />) : (<></>)}
          
          {dashboard?.tagimg ? (
            <img
              className=" h-5 mx-2 cursor-pointer"
              alt="tag"
              src={replaceImageSrc(dashboard?.tagimg)}
            />
          ) : (<></>)}

      </div>
  );
};

export default NewDashboardBox;