// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import axios from "axios";
import { setSession } from "../utils/sessionstore";

const API_ENDPOINT = require("../config/server");
const { getSession } = require("../utils/sessionstore");


export async function searchDashboardService(searchTerm, clientId) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "SEARCH_DASHBOARD",
      Method: "GET",
    },

    Parameters: {
      clientId: clientId,
      search: searchTerm,
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  return response.data;
}
