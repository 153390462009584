// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { Routes, Route } from "react-router-dom";

import NotFound from "../views/NotFound";
import { Suspense, lazy, memo } from "react";

import StartLoader from "../components/loaders/StartLoader";
import PrivateRoute from "./PrivateRoute";
import ResetPassword from "../components/forms/ResetPassword";
import DefaultLayout from "./DefaultRouteLayout";

import LoginLayout from "./LoginRouteLayout";

import UnsubscribedPop from "../components/popups/UnsubscribedPop";

import EmbeddedLinkDashboard from "../views/EmbeddedLinkDashboard";
import AdminRoute from "./AdminRoute";

const Home = lazy(() => import("../views/Home"));
const Dashboard = lazy(() => import("../views/Dashboard"));
const ProfileLayout = lazy(() => import("../layouts/ProfileLayout"));
const Login = lazy(() => import("../views/Login"));
const TicketLayout = lazy(() => import("../layouts/TicketLayout"));
const WhitePaperLayout = lazy(() => import("../layouts/WhitePaperLayout"));

const HwaiLogin = lazy(() => import("../views/HwaiLogin"));

const Router = () => {
  return (
    <>
      <Routes>
        <Route
          path="/:clientID?"
          element={
            <PrivateRoute>
              <Suspense fallback={<StartLoader />}>
                <DefaultLayout>
                  <Home />
                </DefaultLayout>
              </Suspense>
            </PrivateRoute>
          }
        />
        {/* Type here refers to the section from which the user has clicked. */}
        <Route
          path="/:clientID?/dashboard/:id/:type?"
          element={
            <PrivateRoute>
              <Suspense fallback={<StartLoader />}>
                <DefaultLayout>
                  <Dashboard />
                </DefaultLayout>
              </Suspense>
            </PrivateRoute>
          }
        />
        {/* Type here refers to the section from which the user has clicked. */}
        <Route
          path="/redirect/:dashboard/:type?"
          element={
            <PrivateRoute>
              <Suspense fallback={<StartLoader />}>
                <DefaultLayout>
                  <EmbeddedLinkDashboard />
                </DefaultLayout>
              </Suspense>
            </PrivateRoute>
          }
        />

        <Route
          path="/:clientID?/subscribe"
          element={
            <PrivateRoute>
              <Suspense fallback={<StartLoader />}>
                <DefaultLayout>
                  <UnsubscribedPop />
                </DefaultLayout>
              </Suspense>
            </PrivateRoute>
          }
        />

        <Route
          path="/:clientID?/ticket"
          element={
            <PrivateRoute>
              <Suspense fallback={<StartLoader />}>
                <DefaultLayout>
                  <TicketLayout />
                </DefaultLayout>
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/login"
          element={
            <LoginLayout>
              <Suspense fallback={<StartLoader />}>
                <Login />
              </Suspense>
            </LoginLayout>
          }
        />
        <Route
          path="/auth/forgotpassword/:session_id"
          element={<ResetPassword />}
        />

        <Route
          path="/:clientID?/profile"
          element={
            <PrivateRoute>
              <Suspense fallback={<StartLoader />}>
                <DefaultLayout>
                  <ProfileLayout />
                </DefaultLayout>
              </Suspense>
            </PrivateRoute>
          }
        />

        <Route
          path="/:clientID?/whitepapers"
          element={
            <PrivateRoute>
              <Suspense fallback={<StartLoader />}>
                <DefaultLayout>
                  <WhitePaperLayout />
                </DefaultLayout>
              </Suspense>{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/hwai-login"
          element={
            <Suspense fallback={<StartLoader />}>
              <AdminRoute>
                <HwaiLogin />
              </AdminRoute>
            </Suspense>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default memo(Router);
