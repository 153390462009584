// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo, useState } from "react";

import { sendMailToSales } from "../../services/mail.service";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";

import { hideSalesPopUp } from "../../store/auth/action";
import ImageSlider from "../slider/ImageSlider";
import TalkToSalesPop from "./TalktosalesPop";

function UnSubscribedDashboard() {
  const {
    clientname,
    success,
    activedashboard,
    userDetails,
    dashboardClickedArea,
  } = useSelector((state) => ({
    clientname: state.clientname,
    success: state.success,
    activedashboard: state.activedashboard,
    userDetails: state.userDetails,
    dashboardClickedArea: state.dashboardClickedArea,
  }));
  const dispatch = useDispatch();

  const [talktosalessuccess, setTalkToSalesSuccess] = useState(false);

  function formatDateToMMDDYYYY() {
    const currentDate = new Date();

    // Get the individual components of the date
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(currentDate.getDate()).padStart(2, "0");
    const year = String(currentDate.getFullYear());

    // Create the formatted date string
    const formattedDate = `${month}-${day}-${year}`;

    return formattedDate;
  }

  function createEmailSubject() {
    return `User interested in ${activedashboard?.label}`;
  }
  function createEmailBody() {
    const formattedDate = formatDateToMMDDYYYY();
    return `"${userDetails?.firstName} ${userDetails?.lastName}" (${userDetails?.email}) from “${clientname}” has shown interest in ${activedashboard?.label} by clicking on the “${dashboardClickedArea}” section on date – “${formattedDate}” (mm-dd-yyyy)`;
  }
  async function onContactSales() {
    try {
      setTalkToSalesSuccess(true);
      const subject = createEmailSubject();
      const body = createEmailBody();
      const payload = {
        userEmail: userDetails?.email,
        message: body,
        subject: subject,
        senderName: userDetails?.firstName,
      };
      const mailReceipt = await sendMailToSales(payload);
    } catch (err) {
      // dispatch(invokeError(err.message));
      console.log(err);
    }
  }
  function onClose() {
    dispatch(hideSalesPopUp());
  }

  return (
    <div className="absolute z-40 left-0 top-0 right-0 bottom-0 overflow-y-hidden p-8 bg-[rgba(0,0,0,0.5)] flex items-center justify-center">
      <div
        className="bg-[#ffffff] w-[80%] h-max flex flex-col shadow"
        id="modal"
      >
        <div className="flex Roboto flex-row justify-end items-center p-4">
          <div onClick={onClose} className="cursor-pointer">
            <RxCross1 />
          </div>
        </div>
        <div className="w-full h-max flex flex-row px-12 pb-[48px] gap-4">
          <div className="w-1/2 flex flex-col gap-4 justify-start">
            <p className="text-[#5C276E] font-bold text-xl leading-normal text-left">
              {activedashboard?.salesContentId?.heading}
            </p>
            <p className="text-xs Roboto text-[#393B3C] text-left">
              {activedashboard?.salesContentId?.description}
            </p>
            <div className="h-max w-full flex flex-col gap-4">
              {activedashboard?.salesContentId?.features?.map(
                (cardText, index) => (
                  <div key={index} className="gap-4">
                    <div className="flex flex-row items-start ">
                      <img
                        className="scale-50"
                        src="https://lobbystore.blob.core.windows.net/images/check 1.svg"
                        alt="check"
                      />
                      <div className="flex flex-col gap-1 h-max">
                        <div className="font-semibold text-xs text-left">
                          {cardText.split(":")[0]}
                        </div>
                        <div className="text-xs text-left">
                          {cardText.split(":")[1]}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <button
              onClick={onContactSales}
              className="bg-[#FDBC40] border-[#FDBC40] hover:bg-[#ffcb6a] w-1/2 h-max px-6 py-2 mt-4  text-[#333] font-bold text-sm"
            >
              Learn More
            </button>
          </div>
          <div className="w-1/2 flex items-stretch">
            <div className="h-full w-full">
              <ImageSlider
                imageUrls={activedashboard?.salesContentId?.img || []}
              />
            </div>
          </div>
        </div>
      </div>
      {talktosalessuccess && (
        <TalkToSalesPop
          talktosalessuccess={talktosalessuccess}
          setTalkToSalesSuccess={setTalkToSalesSuccess}
        />
      )}
    </div>
  );
}

export default memo(UnSubscribedDashboard);
