// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import axios from "axios";
const API_ENDPOINT = require("../config/server");
const USER_LOG_API = require("../config/userlog.server");
// session start for dashbaord
const { getSession, setSession } = require("../utils/sessionstore");

export async function userLogsAPI(
  type,
  value,
  userDetails,
  clientname,
  pagename,
  clickType,
  clientId,
  dashboardId,
  startTime,
  endTime,
  session_id, // session Id added to call this API after logout
  token // token added to call this API after logout
) {
  try {
    const payload = {
      Client: clientname,
      SessionId: getSession("session_id") || session_id,
      JobTitle: userDetails?.jobTitle,
      UserName: userDetails?.email,
      Department: userDetails?.department,
      PageName: pagename,
      Type: type,
      Value: value,
      clickType: clickType,
      clientId: clientId,
      dashboardId: dashboardId,
      startTime: startTime,
      endTime: endTime,
    };
    const axiosoptions = {
      url: `${USER_LOG_API}`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        session_id: getSession("session_id") || session_id,
        Authorization: `Bearer ${getSession("session_hwai") || token}`,
      },
      data: payload,
    };
    //stops extend session when logging out
    if (getSession("isAuth") === true) {
      await extendSession();
    }
    const response = await axios(axiosoptions);

    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function startDashboardSession(dashboard) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "START_DASHBOARD_SESSION",
      Method: "POST",
    },

    Parameters: {
      dashboardname: dashboard,
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function authenticateDashboardSession() {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "AUTHENTICATE_DASHBOARD_SESSION",
      Method: "POST",
    },

    Parameters: {
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function accessEmbeddedDashboard(client_id, shortcut) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "ACCESS_EMBEDED_DASHBOARD",
      Method: "POST",
    },
    Parameters: {
      clientId: client_id,
      embeded: shortcut,
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function extendSession() {
  try {
    let formattedPayload = {
      Dashboard_Details: {
        Dashboard_Name: "Lobby",
        Dashboard_Version: "V1",
        API_Route: "EXTEND_SESSION",
        Method: "POST",
      },

      Parameters: {
        data: {},
      },
    };

    const axiosoptions = {
      url: `${API_ENDPOINT}`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        session_id: getSession("session_id"),
        Authorization: `Bearer ${getSession("session_hwai")}`,
      },
      data: formattedPayload,
    };

    const response = await axios(axiosoptions);
    const expiresAt = new Date(response.data.session.expires);
    // console.log(expiresAt, "updated expires at");
    setSession("isAuth", true, expiresAt);
    setSession("session_hwai", getSession("session_hwai"), expiresAt);
    setSession("session_id", getSession("session_id"), expiresAt);
    setSession("client_id", getSession("client_id"), expiresAt);
    setSession("userDetails", getSession("userDetails"), expiresAt);
    setSession("clientname", getSession("clientname"), expiresAt);
    setSession("clientSQLId", getSession("clientSQLId"), expiresAt);
    setSession("isAuth_hwai", getSession("isAuth_hwai"), expiresAt);
    setSession("session_hwai_user", getSession("session_hwai_user"), expiresAt);
    setSession("orgs", getSession("orgs"), expiresAt);
  } catch (err) {
    console.log(err);
  }
}
