// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo, useState, useRef, useEffect } from "react";
import StartIcon from "../../img/StartIcon.svg";
import SignOut from "../popups/SignOut";
import RightArrow from "../../img/RightArrow.svg";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import DashboardListBar from "./DashboardListBar";
import { useSelector, useDispatch } from "react-redux";
import {
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  setActiveTourComponent,
} from "../../store/auth/action";
import { useNavigate, Link, useParams } from "react-router-dom";
import LeftForkTourCard from "../tours/LeftForkTourCard";
import UnsubscribedPop from "../popups/UnsubscribedPop";

const SideBar = () => {
  const { clientID } = useParams();

  const { isSalesPopUp, userDetails, orgs } = useSelector((state) => ({
    isSalesPopUp: state.isSalesPopUp,
    userDetails: state.userDetails,
    orgs: state.orgs,
  }));

  const [openSignOut, setOpenSignOut] = useState(false);
  const [openedCat, setOpenCat] = useState("");
  const [expandBar, setExpandBar] = useState(false);
  const [openDashboardList, setOpenDashboardList] = useState(false);
  const [prodCategory, setProdCategory] = useState([]);
  const { subscriptions, logo, clientname, isTourActive, activeTourComponent } =
    useSelector((state) => ({
      subscriptions: state.subscriptions,
      logo: state.logo,
      clientname: state.clientname,
      isTourActive: state.isTourActive,
      activeTourComponent: state.activeTourComponent,
    }));

  const expandSidebarRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  function cancelSignout() {
    setOpenSignOut(false);
  }
  function logOut() {
    try {
      dispatch(logoutRequest());
      setOpenSignOut(false);
      dispatch(logoutSuccess());
      navigate("/login");
    } catch (err) {
      dispatch(logoutFailure(err.message));
    }
  }

  function handleClickOutside(event) {
    if (
      expandSidebarRef.current &&
      !expandSidebarRef.current.contains(event.target) &&
      !event.target.classList.contains("sidebar")
    ) {
      setOpenDashboardList(false);
      setExpandBar(false);
    }
  }

  function afterLandDashboard() {
    setOpenDashboardList(false);
    setExpandBar(false);
  }

  function onTourNext() {
    dispatch(setActiveTourComponent({ name: "Favourite", number: 2 }));
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        onMouseLeave={() => {
          setOpenDashboardList(false);
          setExpandBar(false);
        }}
        ref={expandSidebarRef}
        className={`flex sidebar z-50 h-screen ${
          expandBar ? "w-auto" : ""
        } fixed bottom-0 left-0 top-0  rounded-none  justify-between `}
      >
        <div
          className={`pt-4 p-2 bg-sideBarBg justify-between flex flex-col h-full`}
        >
          {/* Section 1 including product list */}

          <div className="flex  flex-col justify-between items-start gap-4">
            {/* reponsible to expand sidebar */}
            {!expandBar && (
              <div className="flex">
                <div
                  className="cursor-pointer"
                  onClick={() => setExpandBar(true)}
                >
                  <AiOutlinePlusSquare className="text-4xl text-[#ffffff] hover:text-[#7D7D7D]" />
                </div>
              </div>
            )}

            {/* responsible to contract sidebar */}
            {expandBar && (
              <div className={`flex justify-between items-center w-full`}>
                {" "}
                <p className=" text-xs pl-1  text-[#6d6565] flex  Roboto uppercase ">
                  Products{" "}
                </p>
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    setExpandBar(false);
                    setOpenDashboardList(false);
                  }}
                >
                  <AiOutlineMinusSquare className="text-[#ffffff] text-4xl ml-16 cursor-pointer hover:text-[#7D7D7D]" />
                </p>
              </div>
            )}
            {/* List Of products starts  */}

            {subscriptions?.map(({ _id, productcategoryIds, icon, label }) => (
              <div
                className={`${
                  expandBar ? "w-full " : "w-auto"
                } flex cursor-pointer     gap-2 hover:bg-[#282828] hover:text-[#ffffff] text-[#c8c6c6]`}
                key={_id}
              >
                <img
                  alt="Product-Icon"
                  src={icon}
                  className="cursor-pointer p-0 m-0 product-icons w-[34px] h-[34px] "
                  onMouseOver={() => {
                    setProdCategory(productcategoryIds);
                    setExpandBar(true);
                    // if (openDashboardList === true) {
                    //   setOpenDashboardList(false);
                    //   setExpandBar(false);
                    // } else {
                    //   setExpandBar(!expandBar);
                    // }
                  }}
                />

                <div
                  onMouseOver={() => {
                    setProdCategory(productcategoryIds);

                    setOpenCat(label);

                    setOpenDashboardList(true);
                  }}
                  className={`${
                    !expandBar
                      ? "hidden text-left   translate-x-0 duration-500"
                      : "flex  justify-between w-full  "
                  }`}
                >
                  <p
                    className={` text-left  text-sm flex align-middle pt-[2px]   overflow-clip cursor-pointer text-[12px] mt-1 Roboto`}
                  >
                    {label}
                  </p>
                  <p className={`align-middle mt-1  `}>
                    <img
                      src={RightArrow}
                      alt="next"
                      className={`w-7 `} //${!expandBar ? "hidden" : ""}
                    />
                  </p>
                </div>
              </div>
            ))}

            {/* List Of Products Ends */}
          </div>
          {/* Section 1 ends */}

          {/* Section 2 including  Sign out and profile icons */}
          <div className=" flex flex-col justify-between items-start gap-3 cursor-pointer pb-4">
            <div className="flex ml-1">
              <img
                alt="Start-Icon"
                src={StartIcon}
                className="border-b w-7 h-8  pb-3    hover:border-[#ffffff] border-[#c8c6c6]  "
                onClick={() => {
                  setOpenSignOut(true);
                }}
              />
              {expandBar && (
                <div className="Roboto ml-3  hover:text-[#ffffff] text-[#c8c6c6] text-sm ">
                  {"Logout"}
                </div>
              )}
            </div>
            {/* <p onClick={()=>{setOpenSignOut(true)}}><AiOutlineLogout className="text-[#ffffff] text-3xl hover:text-[#7D7D7D] hover:text-2xl cursor-pointer" /></p> */}
            <div
              onClick={() => {
                if (
                  clientID !== undefined &&
                  orgs.map((org) => org._id).includes(clientID) &&
                  userDetails?.permissions === "HWAI_ADMIN"
                ) {
                  navigate(`/${clientID}/profile`);
                } else {
                  navigate("/profile");
                }
              }}
            >
              <div className="flex Roboto ml-[4px]  ">
                <img
                  alt="logo"
                  src={logo}
                  className="w-7 h-7 "
                  onClick={() => {
                    setExpandBar(false);
                    setOpenDashboardList(false);
                    navigate("/profile");
                  }}
                />
                {expandBar && (
                  <div className="Roboto ml-3 mt-1 hover:text-[#ffffff] text-[#c8c6c6] text-sm ">
                    {clientname}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Section 2 ends */}

        {openDashboardList && (
          <DashboardListBar
            dashBoardCategories={prodCategory}
            afterLandDashboard={afterLandDashboard}
          />
        )}

        {isTourActive && activeTourComponent?.name === "Nav" && (
          <div className=" ml-4 mt-3">
            <LeftForkTourCard
              onNext={onTourNext}
              icon="https://lobbystore.blob.core.windows.net/tour/navtour.svg"
              title={"Collapsible Navigation Bar"}
              description={
                "Our dashboard lists have been reorganized for easier navigation. All products are now conveniently located in a two-fold collapsible sidebar."
              }
              number={1}
            />
          </div>
        )}
      </div>

      {/* Sign out pop up open on click of signOut icon */}
      {isSalesPopUp && <UnsubscribedPop />}
      {openSignOut && <SignOut onCancel={cancelSignout} onConfirm={logOut} />}
    </>
  );
};

export default memo(SideBar);
