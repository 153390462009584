// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo } from "react";

const Footer = () => {
  return (
    <div className=" z-20 fixed bottom-0 left-[45px]  flex items-center justify-center right-0 bg-footerBg h-7">
      <div className="text-xs">
        Copyright © {new Date().getFullYear()} HealthWorksAI, All Rights
        Reserved.
      </div>
    </div>
  );
};

export default memo(Footer);
