// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo } from "react";
import { useDispatch } from "react-redux";
import {
  hideTour,
  setTourActivity,
  setActiveTourComponent,
} from "../../store/auth/action";
import { AiOutlineArrowRight, AiOutlineRight } from "react-icons/ai";

const LeftForkTourCard = ({ onNext, number, icon, title, description }) => {
  const dispatch = useDispatch();
  function onskip() {
    dispatch(hideTour());
    dispatch(setTourActivity(false));
    dispatch(setActiveTourComponent({}));
  }
  return (
    <>
      <div
        className={`relative z-50 bg-[#333333] w-[357px] h-max text-[#ffffff] Roboto flex justify-center items-center p-4`}
      >
        <div className="w-6 h-6 -z-10 absolute top-0 left-0 -translate-x-[11px] translate-y-[10px] bg-[#333333] rotate-45"></div>
        <div className="w-full h-max flex flex-row gap-2">
          <div className="h-full w-[17px] pt-1">
            <img src={icon} alt="i" className="" />
          </div>
          <div className="w-full h-max">
            <div className="w-full h-max flex flex-col gap-2">
              <p className="text-[13px] text-left uppercase leading-[15px] py-1 font-semibold">
                {title}
              </p>
              <p className="Roboto text-[14px] text-left leading-[20px] tracking-[0.7px]">
                {description}
              </p>
              <div className="flex justify-between items-center ">
                <div className="text-[14px] tracking-[0.7px]">
                  {number} of 6
                </div>

                <div className="flex flex-row items-center gap-1">
                  <button
                    onClick={onskip}
                    className=" hover:text-[#c2c2c2] px-2 py-1 text-[14px] flex items-center"
                  >
                    Skip tour
                  </button>
                  <button
                    className="bg-[#ffffff] text-[14px] hover:bg-[#f0f0f0] text-[#333333] px-2  py-1 flex flex-row gap-1 items-center"
                    onClick={onNext}
                  >
                    Next <AiOutlineRight className="fill-[#333333]" size={15} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(LeftForkTourCard);
