// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import axios from "axios";
const dotenv = require("dotenv");
require("dotenv").config();
const API_ENDPOINT = require("../config/server");
const passphrase = `${process.env.REACT_APP_ENCRYPTION_KEY}`;
const { getSession } = require("../utils/sessionstore");

const CryptoJS = require("crypto-js");
// fake commit
export async function authenticate(payload) {
  const encryptedString = CryptoJS.AES.encrypt(
    payload.password.trim(),
    passphrase
  ).toString();

  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "LOGIN_USER",
      Method: "POST",
    },

    Parameters: {
      data: { email: payload.email.trim(), password: encryptedString },
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function forgotUserPassword(email) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "FORGOT_PASSWORD",
      Method: "POST",
    },

    Parameters: {
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      user: email,
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };
  const response = await axios(axiosoptions);

  return response.data;
}

export async function resetPassword(payload) {
  const urlPath = window.location.pathname;
  const sessionId = urlPath.split("/").pop();
  const encryptedString = CryptoJS.AES.encrypt(
    payload.password.trim(),
    passphrase
  ).toString();

  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "RESET_USER_PASSWORD",
      Method: "PATCH",
    },

    Parameters: {
      data: { password: encryptedString },
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: sessionId,
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };
  const response = await axios(axiosoptions);

  return response.data;
}

export async function authenticateTableau() {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "AUTHENTICATE_TABLEAU",
      Method: "GET",
    },

    Parameters: {
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
  };
  const response = await axios(axiosoptions);

  return response.data;
}

export async function getTableauTicket(targetSite, accesstype) {
  try {
    // console.log(accesstype, "I am the access type")
    let formattedPayload = {
      Dashboard_Details: {
        Dashboard_Name: "Lobby",
        Dashboard_Version: "V1",
        API_Route: "GET_TABLEAU_TICKET",
        Method: "GET",
      },

      Parameters: {
        target_site: targetSite,
        accesstype: accesstype,
        data: {},
      },
    };

    const axiosoptions = {
      url: `${API_ENDPOINT}`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        session_id: getSession("session_id"),
        Authorization: `Bearer ${getSession("session_hwai")}`,
      },
      data: formattedPayload,
    };
    const response = await axios(axiosoptions);
    // console.log(response.data, "reponse.data from service");

    return response.data;
  } catch (err) {
    console.log("ERROR", err);
  }
}

export async function verifySession() {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "VERIFY_SESSION",
      Method: "POST",
    },

    Parameters: {
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };
  const response = await axios(axiosoptions);

  return response.data;
}


export async function logoutUser(token, session_id) { // session Id & token added to call this API after logout 
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "LOGOUT_SESSION",
      Method: "POST",
    },

    Parameters: {
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id") || session_id,
      Authorization: `Bearer ${getSession("session_hwai") || token}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };
  const response = await axios(axiosoptions);

  return response.data;
}