// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import "./App.css";

import Router from "./router/Router";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSelector } from "react-redux";
import FullScreenLoader from "./components/loaders/FullScreenLoader";

function App() {
  const { isLoading } = useSelector((state) => ({
    isLoading: state.isLoading,
  }));

  return (
    <div className="App">
      <DndProvider backend={HTML5Backend}>
        <Router />
        {isLoading && <FullScreenLoader />}
      </DndProvider>
    </div>
  );
}

export default App;
