// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo } from "react";
import searchIcon from "../../img/searchIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { searchDashboardService } from "../../services/search.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import {
  setActiveDashboard,
  showSalesPopUp,
  showVideoPopup,
} from "../../store/auth/action";
import { getSession } from "../../utils/sessionstore";
import { userLogsAPI } from "../../services/session.service";

const SearchBar = () => {
  const { subscriptions, token, userDetails, clientname } = useSelector(
    (state) => ({
      subscriptions: state.subscriptions,
      token: state.token,
      userDetails: state.userDetails,
      clientname: state.clientname,
    })
  );
  const sessionId = getSession("session_id");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [result, setResult] = useState([{ dashboardname: "No Data found" }]);
  const navigate = useNavigate();
  const { clientID } = useParams();

  async function searchDashboard(token, searchTerm) {
    try {
      const token = getSession("session_hwai");
      const client_id = getSession("client_id");
      if (!token) {
        navigate("/login");
      } else {
        const response = await searchDashboardService(searchTerm, client_id);

        if (response.dashboards?.length > 0) {
          setResult(response.dashboards);
        } else {
          setResult({ dashboardname: "No Data found" });
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  useEffect(() => {
    if (searchTerm?.length > 0) {
      setSearchActive(true);
      searchDashboard(token, searchTerm);
    }
  }, [searchTerm]);

  async function callUserLogAPI(
    dashboardName,
    standarddashboardName,
    dashboardId,
    clickType
  ) {
    try {
      const logresponse = userLogsAPI(
        "SEARCH_DASHBOARD",
        standarddashboardName,
        userDetails,
        clientname,
        dashboardName,
        clickType,
        getSession("client_id"),
        dashboardId
      );
      // console.log(logresponse, "banner click");
    } catch (err) {
      console.log(err.message);
    }
  }

  async function landDashboard(dashboard) {
    setSearchTerm("");

    let matched = false;
    let choosenDashboard;

    for (let p in subscriptions) {
      for (let c in subscriptions[p].productcategoryIds) {
        for (let d in subscriptions[p].productcategoryIds[c].dashboardIds) {
          if (
            subscriptions[p].productcategoryIds[c].dashboardIds[
              d
            ].dashboardname.trim() === dashboard.dashboardname.trim() ||
            subscriptions[p].productcategoryIds[c].dashboardIds[
              d
            ].label.trim() === dashboard.label.trim()
          ) {
            matched = true;
            choosenDashboard =
              subscriptions[p].productcategoryIds[c].dashboardIds[d];

            break;
          }
        }
      }
    }

    if (matched) {
      if (choosenDashboard?.type === "Open in new tab") {
        callUserLogAPI(
          choosenDashboard?.label,
          choosenDashboard?.standarddashboardId?.standardName,
          choosenDashboard?._id,
          "Viewed"
        );
        window.open(choosenDashboard?.url, "_blank");
        return;
      }

      if (choosenDashboard?.type === "Data Cube") {
        callUserLogAPI(
          choosenDashboard?.label,
          choosenDashboard?.standarddashboardId?.standardName,
          choosenDashboard?._id,
          "Viewed"
        );
        const cubeUrl = choosenDashboard?.url.split("=")[0];
        window.open(cubeUrl + "=" + userDetails?.email, "_blank");
        return;
      }

      dispatch(setActiveDashboard(choosenDashboard));

      if (choosenDashboard?.type === "video") {
        callUserLogAPI(
          choosenDashboard?.label,
          choosenDashboard?.standarddashboardId?.standardName,
          choosenDashboard?._id,
          "Viewed"
        );
        dispatch(showVideoPopup());
        return;
      }

      if (
        location.pathname ===
          `/${clientID}/dashboard/${choosenDashboard?._id}/SEARCH_DASHBOARD` ||
        location.pathname ===
          `/dashboard/${choosenDashboard?._id}/SEARCH_DASHBOARD`
      ) {
        return;
      }

      callUserLogAPI(
        choosenDashboard?.label,
        choosenDashboard?.standarddashboardId?.standardName,
        choosenDashboard?._id,
        "Viewed"
      );
      if (userDetails?.permissions === "HWAI_ADMIN" && clientID !== undefined) {
        navigate(
          `/${clientID}/dashboard/${choosenDashboard?._id}/SEARCH_DASHBOARD`
        );
      } else {
        navigate(`/dashboard/${choosenDashboard?._id}/SEARCH_DASHBOARD`);
      }
    } else {
      dispatch(setActiveDashboard(dashboard));
      // console.log(dashboard, "active");
      dispatch(showSalesPopUp("Search box"));
      callUserLogAPI(
        dashboard?.label,
        dashboard?.standarddashboardId?.standardName,
        dashboard?._id,
        "Talk To Sales"
      );
      // console.log("NOT FOUND");
    }
  }

  return (
    <>
      <div
        className="pt-2 relative  text-gray-600 w-[260px] Roboto "
        onMouseLeave={() => {
          setSearchTerm("");
        }}
      >
        <input
          className="border w-full border-[#7D7D7D] bg-[#FFFFFF] h-8 px-5 pr-10 text-sm focus:outline-none placeholder:text-[#d6d6d6]"
          // type="textbox"
          // name="search"
          placeholder="Search a Dashboard"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
        <button type="submit" className="absolute right-0 top-0 mt-4 mr-2">
          <img alt="Search" src={searchIcon} />
        </button>
        {searchActive && result?.length > 0 && searchTerm.trim().length > 0 && (
          <div
            onMouseLeave={() => {
              setSearchActive(false);
            }}
            className="relative top-0"
          >
            <div className="absolute z-50 bg-[#ffffff] shadow-md h-64 w-full text-left overflow-y-scroll px-4 cursor-pointer ">
              {result?.map((res) => (
                <p
                  key={uuidv4()}
                  className="my-4 Roboto uppercase text-sm"
                  onClick={() => {
                    landDashboard(res);
                  }}
                >
                  {res.label}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(SearchBar);
