// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo } from "react";
import GeneralButton from "../buttons/GeneralButton";
import TransaparentButton from "../buttons/TransaparentButton";

import { AiOutlineLogout } from "react-icons/ai";

function SignOut({ onCancel, onConfirm }) {
  return (
    <div className="z-50 fixed w-screen h-screen bg-[rgba(0,0,0,0.5)] top-0 bottom-0 left-0 right-0 flex items-center justify-center">
      <div className="bg-[#ffffff] shadow h-max w-max">
        <div className="p-8 flex flex-col gap-8">
          <div className="flex flex-col">
            <div className="font-bold text-[#333333] flex flex-row gap-3">
              <AiOutlineLogout className="mt-1" />
              <div className="Roboto font-bold">Logout</div>
            </div>

            <div className="text-left w-[380px]">
              <p className="Roboto">Are you sure want to sign out?</p>
            </div>
          </div>

          <div className="flex justify-end flex-row gap-2">
            <TransaparentButton text={"Confirm"} onclick={onConfirm} />{" "}
            <GeneralButton text={"Cancel"} onclick={onCancel} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(SignOut);
