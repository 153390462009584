// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo, useEffect } from "react";

import logo from "../../img/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import ExpiredSession from "../../views/ExpiredSession";
import { useState } from "react";

import InputError from "../errors/InputError";
import { resetPassword, verifySession } from "../../services/auth.service";
import { invokeError, invokeSuccess } from "../../store/auth/action";
import SuccessPop from "../popups/SuccessPop";

import { useNavigate, useParams } from "react-router-dom";
import StartLoader from "../loaders/StartLoader";
import ActionError from "../errors/ActionError";
const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const ResetPassword = () => {
  const { success, isLoading, failure } = useSelector((state) => ({
    success: state.success,
    isLoading: state.isLoading,
    failure: state.failure,
  }));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { session_id } = useParams();
  const [sessionExist, setSessionExist] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  async function sessionVerification() {
    const res = await verifySession();
    if (res === "False") {
      setSessionExist(false);
    }
  }

  useEffect(() => {
    sessionVerification();
  }, [session_id]);

  async function SaveDetails() {
    try {
      const payload = { password: password, confirmPassword: confirmPassword };

      const response = await resetPassword(payload);
      dispatch(invokeSuccess("Password updated !"));

      const timer = setTimeout(() => {
        navigate("/login");
      }, 2000); // Set the timer duration in milliseconds

      return () => clearTimeout(timer);
    } catch (err) {
      console.log(err, "error");

      dispatch(invokeError("Something went wrong"));
      setSessionExist(false);
    }
  }

  return (
    <>
      {!sessionExist ? (
        <ExpiredSession />
      ) : (
        <div className="h-screen bg-[#ffffff] w-screen flex items-center justify-center ">
          <div className="flex flex-col items-center justify-center gap-4">
            <div className=" flex flex-col items-center justify-center border-2  w-max h-max p-14 gap-6">
              <div className="text-center flex flex-col justify-center w-[306px] h-max">
                <div className="flex justify-center w-full h-max px-6 pb-4">
                  <img alt="logo" src={logo} className="object-cover" />
                </div>
              </div>
              {/* form starts */}
              <div className="Roboto w-max h-max ">
                <div className="flex w-max">
                  <div className="flex flex-col text-left gap-4 w-max">
                    <div className="flex flex-col gap-1">
                      <div className="flex gap-2">
                        <p className="text-[#7D7D7D] uppercase text-sm font-semibold Roboto tracking-[0.7px]">
                          New password
                        </p>
                        <span className="text-[#DC3545]">*</span>
                      </div>
                      <div className="border border-[#7d7d7d] flex flex-row pr-2 w-[306px] h-[40px]">
                        <input
                          value={password}
                          placeholder="Enter password"
                          className="w-full text-base leading-5 Roboto outline-none py-[10px] px-2 placeholder-[#DDDDDC] tracking-[0.8px] bg-[#ffffff]"
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-1">
                      <div className="flex gap-2">
                        <p className="text-[#7D7D7D] uppercase text-sm font-semibold Roboto tracking-[0.7px]">
                          Confirm password
                        </p>
                        <span className="text-[#DC3545]">*</span>
                      </div>
                      <div className="border border-[#7d7d7d] flex flex-row pr-2 w-[306px] h-[40px]">
                        <input
                          value={confirmPassword}
                          placeholder="Confirm password"
                          type="password"
                          className="w-full text-base leading-5 Roboto outline-none py-[10px] px-2 placeholder-[#DDDDDC] tracking-[0.8px] bg-[#ffffff]"
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {password.length < 6 &&
                      password.length > 0 &&
                      confirmPassword.length > 0 && (
                        <InputError error={"Password is too short"} />
                      )}
                    {!(password === confirmPassword) && (
                      <InputError error={"Passwords are not matching"} />
                    )}

                    <button
                      type="submit"
                      className={`${
                        password.trim().length < 6 ||
                        confirmPassword !== password
                          ? "bg-[#BDA7C4] hover:bg-[#BDA7C4]"
                          : "bg-[#5C276E] hover:bg-[#4A1E57]"
                      } text-[#ffffff] Roboto w-[306px] h-[40px] tracking-[0.8px]`}
                      onClick={() => SaveDetails()}
                      disabled={
                        !(password === confirmPassword && password.length > 6)
                      }
                    >
                      Reset Password
                    </button>

                    {/* Remember and forget password */}
                  </div>
                </div>
              </div>
              {/*form ends  */}
            </div>
          </div>
        </div>
      )}
      {success && <SuccessPop />}
      {failure && <ActionError errorMsg="Something Went Wrong" />}
      {isLoading && <StartLoader />}
    </>
  );
};

export default memo(ResetPassword);
