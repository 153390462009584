// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import axios from "axios";
import { setSession } from "../utils/sessionstore";
const API_ENDPOINT = require("../config/server");
const { getSession } = require("../utils/sessionstore");

// static campaign - log based auto feedback

export async function getCampaignForDashboard(payload) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "GET_STATIC_CAMPAIGN",
      Method: "POST",
    },

    Parameters: {
      data: {
        clientId: payload.clientId,
        standardDashboardId: payload.standardDashboardId,
      },
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function saveCampaignFeedback(payload) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "STATIC_CAMPAIGN_FEEDBACK",
      Method: "POST",
    },

    Parameters: {
      data: payload,
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

// dynamic campaign - admin cotrolled feedback

export async function getDynamicCampaignsAPI(payload) {
  // payload = {client: string id, user: string id}
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "GET_CAMPAIGN_POPUP",
      Method: "POST",
    },

    Parameters: {
      data: payload,
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function updateDynamicCampaginUserMapAPI(payload) {
  // payload = { id: string id, viwedOnceOrSubmitted: boolean, apearanceCounter: number, lastAppearedOn: Date }
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "UPDATE_CAMPAIGN",
      Method: "POST",
    },

    Parameters: {
      data: payload,
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  return response.data;
}
