// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo, useEffect, useRef } from "react";
import { getSession } from "../utils/sessionstore";
import {
  addDashboardFeedback,
  addOrgs,
  addTimerPopUp,
  refreshRequest,
  refreshSuccess,
  refreshFailure,
  listBanner,
  listFavourites,
  listNotification,
  newslistSuccess,
  listWhitePapers,
  updateFeedbackStatus,
  listSubscriptions,
  listTrendings,
  listUserDetails,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  setDashboardPopUp,
} from "../store/auth/action";
import { refreshService } from "../services/refresh.service";
import { getAllClients } from "../services/hwai_user.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "../views/NotFound";


const PrivateRoute = ({ children }) => {
  const { subscriptions, userDetails } = useSelector((state) => ({
    subscriptions: state.subscriptions,
    userDetails: state.userDetails,
  }));

  const { clientID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sessionId = getSession("session_id");
  const token = getSession("session_hwai");
  const hwai_user_token = getSession("session_hwai_user");
  const client_id = getSession("client_id");
  const pathParts = useLocation().pathname.split("/");
  const sessionIntervalRef = useRef(null);

  const subscriptionActive = useSelector((state) => state.subscriptionActive);

  // Helper function to validate MongoDB ObjectId
  const isValidMongoId = (id) => {
    return id?.length === 24 && /^[a-f\d]{24}$/i.test(id);
  };

  // Refresh session and fetch data
  const onRefresh = async (token, hwai_user_token, clientid) => {
    try {
      const payload = { token, clientid, sessionId };
      console.log('Payload',payload);
      dispatch(refreshRequest(payload));

      let orglist = [];
      if (hwai_user_token) {
        const response = await getAllClients();
        orglist = response?.clients;
        console.log('Org List',orglist);
        dispatch(addOrgs(orglist));
      }
      
      const response = await refreshService(payload);
      console.log('Response',response);
      const { data } = response?.data;
      console.log('Refresh Data',data);
      const userData = data?.user;
      const sessionData = response?.data?.session;

      dispatch(addDashboardFeedback(data?.dashboardfeedback));
      dispatch(refreshSuccess(sessionData, userData?.clientId?.logo, userData?.clientId, orglist, {
        userSQLId: userData?.userSQLId,
        trendingConfig: userData?.trendingConfig,
        userName: userData?.userName,
        email: userData?.email,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        address: userData?.address,
        mobile: userData?.mobile,
        about: userData?.about,
        permissions: userData?.permissions,
        jobTitle: userData?.jobTitle,
        department: userData?.department,
        userId: userData?._id,
        clientId: userData?.clientId?._id,
      }));

      dispatch(listNotification(data?.notification));
      dispatch(listBanner(userData?.bannerId || {}));
      dispatch(listSubscriptions(data?.subscriptions));
      dispatch(listFavourites(data?.favourites?.slice(0, 12)));
      dispatch(listWhitePapers(userData?.whitepaperIds));
      dispatch(listTrendings(data?.trendings));
      dispatch(listUserDetails(userData));
      dispatch(newslistSuccess(data?.newsinfo));
      dispatch(updateFeedbackStatus(data?.feedback));
      dispatch(setDashboardPopUp(userData?.clientId?.config?.dashboardpopup));
    } catch (err) {
      console.error("Error in refresh:", err);
      // dispatch(refreshFailure(err.message));
    }
  };

  // Logout function
  const logOut = () => {
    try {
      dispatch(logoutRequest());
      dispatch(logoutSuccess(token, sessionId));
      navigate("/login");
    } catch (err) {
      dispatch(logoutFailure(err.message));
    }
  };

  // Session check
  useEffect(() => {
    const sessionCheck = () => {
      if (!getSession("session_hwai") || !getSession("session_id")) {
        logOut();
      }
    };

    sessionIntervalRef.current = setInterval(sessionCheck, 1000);
    return () => clearInterval(sessionIntervalRef.current);
  }, []);

  // Initial data load
  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else if (!subscriptions?.length) {
      onRefresh(token, hwai_user_token, clientID || client_id);
    }
  }, [token, hwai_user_token]);

  // Handle subscription state
  useEffect(() => {
    if (!subscriptionActive && pathParts[1] && userDetails?.permissions !== "HWAI_ADMIN") {
      navigate("/");
    }
  }, [subscriptionActive]);
 
  //invalid route after login
  useEffect(() => {
    if (clientID && !isValidMongoId(clientID)) {
      const localClientId = getSession("client_id"); // Fetch from local storage or session
      if (localClientId && userDetails.permissions==='HWAI_ADMIN') {
        onRefresh(token, hwai_user_token, localClientId);
        navigate(`/${localClientId}`);
        
      }
    }
  }, [clientID]);

  // Render children or redirect to NotFound
  return clientID && !isValidMongoId(clientID) ? <NotFound/> : children;

};

export default memo(PrivateRoute);
