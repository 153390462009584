// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { useSelector , useDispatch} from "react-redux";
import {
  logoutFailure,
  logoutRequest,
  logoutSuccess,
} from "../../store/auth/action";
import { useLocation, useNavigate } from "react-router-dom";
import { TbMoodSmile } from "react-icons/tb";

const ThankyouPop = ({
  expiredpopup,
  setExpiredPopup,
  loader,
  setLoader,
  thankyoupoup,
  setThankyouPopup,
  thankyoumsg,
  thankyousubmsg,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function logOut() {
    try {
      dispatch(logoutRequest());
      dispatch(logoutSuccess());
      navigate("/login");
    } catch (err) {
      dispatch(logoutFailure(err.message));
    }
  }

  return (
    <div className="absolute top-0 left-0 z-[100] select-none w-screen h-screen bg-[#0000001f] filter backdrop-blur-[3px] flex flex-col justify-center items-center gap-4">
      <span className="bg-[white] w-[400px] transition-all duration-200 ease-in-out items-center flex justify-center flex-col px-8 py-4 gap-4 ">
        <div className="h-[60px] w-[60px] rounded-full flex justify-center items-center bg-[#F7F7F7] border border-[#E9E8E8] [box-shadow:_0px_2px_8px_0px_rgba(0,0,0,0.16)]">
          <TbMoodSmile className="text-[32px] text-[#B8B8B8]" />
        </div>
        <div className="flex flex-col justify-center items-center gap-4">
          <div className="text-[20px] fontweight-500 text-[#7D528B]">
            {thankyoumsg}
          </div>
          <div className="text-[14px] text-[#7D528B]">{thankyousubmsg}</div>
        </div>
        <button
          className="cursor-pointer text-[13px] font-semibold bg-[#7D528B] px-4 py-2 text-[white] rounded-full"
          onClick={() => {
            setThankyouPopup(false);
            setExpiredPopup(true);
            logOut();
          }}
        >
          Logout
        </button>
      </span>
    </div>
  );
};

export default ThankyouPop;
