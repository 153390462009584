// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import axios from "axios";
import { setSession } from "../utils/sessionstore";
import { extendSession } from "./session.service";
const API_ENDPOINT = require("../config/server");
const { getSession } = require("../utils/sessionstore");

export async function refreshService(payload) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "REFRESH_APP",
      Method: "POST",
    },

    Parameters: {
      clientid: payload.clientid,
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  await extendSession();
  const response = await axios(axiosoptions);

  return response;
}
