// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { useSelector } from "react-redux";

const TalkToSalesPop = ({ talktosalessuccess, setTalkToSalesSuccess }) => {
  const { activedashboard, userDetails } = useSelector((state) => ({
    activedashboard: state.activedashboard,
    userDetails: state.userDetails,
  }));

  return (
    <div className="absolute top-0 left-0 z-[100] select-none w-screen h-screen bg-[#0000001f] filter backdrop-blur-[3px] flex flex-col justify-center items-center gap-4">
      <span className="bg-[white] w-[400px] transition-all duration-200 ease-in-out items-center flex justify-center flex-col px-8 py-4 gap-4 ">
        <div className="flex flex-col justify-center items-center gap-4">
          <div className="text-[14px] text-[#7D528B]">
            Thanks {userDetails?.firstName} for showing interest in the{" "}
            {activedashboard?.label} offering. Your client partner will reach
            out to you in the next 24 hours.
          </div>
        </div>
        <button
          className="cursor-pointer text-[13px] font-semibold bg-[#7D528B] px-4 py-2 text-[white] rounded-full"
          onClick={() => {
            setTalkToSalesSuccess(false);
          }}
        >
          Close
        </button>
      </span>
    </div>
  );
};

export default TalkToSalesPop;
