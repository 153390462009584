// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideVideoPopup } from "../../store/auth/action";
import VideoPlayer from "react-video-js-player";
// import {Media, Video } from '@vidstack/player-react';
import ReactPlayer from "react-player";
import { RxCross1 } from "react-icons/rx";

const VideoPlayerPopup = () => {
  const dispatch = useDispatch();
  const [player, setPlayer] = useState({});
  const { activedashboard, isVidePop } = useSelector((state) => ({
    activedashboard: state.activedashboard,
    isVidePop: state.isVidePop,
  }));

  const onPlayerReady = (playObj) => {
    setPlayer(playObj);
  };

  const playerRef = useRef(null);

  const handleReady = () => {
    // Access the underlying video element and set disablePictureInPicture
    if (playerRef.current) {
      const videoElement = playerRef.current.getInternalPlayer();
      if (videoElement) {
        videoElement.setAttribute("disablePictureInPicture", "true");
      }
    }
  };
  ////////////////

  const modalRef = useRef(null);
  const bgRef = useRef(null);

  const handleClickOutside = (e) => {
    // console.log("handleClickOutside", activedashboard);
    if (
      isVidePop &&
      bgRef.current &&
      bgRef.current.contains(e.target) &&
      modalRef.current &&
      !modalRef.current.contains(e.target)
    ) {
      // console.log("handleClickOutside -> IF");
      dispatch(hideVideoPopup());
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.addEventListener("click", handleClickOutside);
    }, 0);

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isVidePop]);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault(); // Prevent the default right-click context menu
    };
    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.addEventListener("contextmenu", handleContextMenu);
      return () => {
        modalElement.removeEventListener("contextmenu", handleContextMenu);
      };
    }
  });

  return (
    <div
      ref={bgRef}
      className="z-[100] fixed w-screen h-screen flex items-center justify-center bg-[rgba(0,0,0,0.5)] top-0 bottom-0 left-0 right-0"
    >
      <div
        ref={modalRef}
        className="w-[66vw] h-max relative shadow  bg-[#000000] flex flex-col items-center"
      >
        <div className="justify-between items-center w-full py-4 px-4 flex flex-row h-max border-b border-[#ffffff73]">
          <div className="font-[Roboto] text-[16px] text-[white] tracking-[1px]">
            {activedashboard.dashboardname || "Video Recording"}
          </div>
          <RxCross1
            onClick={() => dispatch(hideVideoPopup())}
            className="text-[20px] text-[white] cursor-pointer"
          />
        </div>
        <div className="w-full flex items-center justify-center h-[70vh]">
          {/* <iframe
            onContextMenu={(e) => e.preventDefault()}
            id="i_frame"
            loading="lazy"
            className=" overflow-hidden w-full h-[550px] overflow-y-auto"
            allowFullScreen={false}
            title="Document viewer"
            src={`${
              
              activedashboard?.url + "#toolbar=0&navpanes=0"
            }`}
          ></iframe> */}

          {/* <VideoPlayer
					controls={true}
					src={activedashboard?.url}
					poster={activedashboard?.icon}
					width="720"
					height="420"
					onReady={onPlayerReady}
          controls={true}
				/> */}

          {/* <Media className=" ">
  <Video loading="visible"  light={"true"} controls preload="true">
    <video loading="visible"  src={activedashboard?.url} preload="none" data-video="0" controls />
  </Video>
</Media> */}

          <ReactPlayer
            ref={playerRef}
            onReady={handleReady}
            playing={true}
            width={"100%"}
            height={"100%"}
            style={{ minWidth: "640px", minHeight: "340px" }}
            light={"true"}
            url={activedashboard?.url}
            controls
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(VideoPlayerPopup);
