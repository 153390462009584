// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { setSession } from "../utils/sessionstore";
import axios from "axios";
const { getSession } = require("../utils/sessionstore");
const token = getSession("session_hwai");
const session_id = getSession("session_id");

require("dotenv").config();
const API_ENDPOINT = require("../config/server");
//CLIENT_LOGIN_FOR_RANDOM_USER
export async function getAllClients() {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "GET_CLIENTS",
      Method: "GET",
    },

    Parameters: {
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function loginForAClient(payload) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "CLIENT_LOGIN_FOR_ADMIN_USER",
      Method: "POST",
    },

    Parameters: {
      clientid: payload.clientid,
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
  };

  const response = await axios(axiosoptions);

  return response.data;
}
