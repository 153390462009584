// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
          <div className="bg-white p-8 shadow-lg">
            <h2 className="text-xl font-bold mb-4">Something went wrong</h2>
            <p className="text-gray-700">
              Please try refreshing the page or contact support if the problem
              persists.
            </p>
          </div>
        </div>
      );
    }

    // Render the children components if no error or ChunkLoadError
    return this.props.children;
  }
}

export default ErrorBoundary;
