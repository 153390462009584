// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo, useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { FaRegCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { acceptSuccess } from "../../store/auth/action";

const SuccessPop = () => {
  const dispatch = useDispatch();
  const { msg } = useSelector((state) => ({
    msg: state.msg,
  }));

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
    const timer = setTimeout(() => {
      setVisible(false);
      dispatch(acceptSuccess());
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch]);
  return (
    <div className="z-[100] fixed w-screen h-screen top-0 bottom-0 left-0 right-0 flex items-end justify-center">
      <div
        className={`bg-[#ffffff] transition-transform delay-500 mb-16 shadow-xl duration-1000 ${
          visible ? "translate-y-72" : "translate-y-0"
        } shadow h-max w-max flex flex-col gap-2`}
      >
        <div className="flex flex-col w-max px-[24px] py-[16px] border-l-8 border-2 border-[#28A745]">
          <div className="flex w-full justify-between">
            <div className="Roboto flex items-center gap-2 text-zinc-600 text-base font-bold leading-normal tracking-wide">
              <span>
                <FaRegCheckCircle />
              </span>
              {msg} Successfully
            </div>
            <div className="flex items-center">
              <RxCross1
                className="cursor-pointer"
                onClick={() => dispatch(acceptSuccess())}
              />
            </div>
          </div>
          <div className="Roboto pt-[8px] pl-6 flex items-center w-[344px] text-zinc-600 text-[13px] font-normal leading-none tracking-wide">
            Your changes are updated successfully
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SuccessPop);
