// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { useEffect, useState, useRef } from "react";
import {
  MdClose,
  MdHelpOutline,
  MdList,
  MdOutlineAdd,
  MdOutlineRemove,
  MdOutlineSearch,
} from "react-icons/md";
import { FaHashtag } from "react-icons/fa";
import "./richTextEditor.css";

export default function HelpGuide({
  sectionLog,
  open,
  setOpen,
  data,
  setData,
  chapterLog,
  setChapterLog,
}) {
  const [search, setSearch] = useState("");
  const sectionRefs = useRef({});

  const scrollToSection = (sectionId) => {
    sectionRefs.current[sectionId]?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      onClick={() => {
        setOpen(false);
      }}
      className={`fixed ani left-0 top-0 w-screen h-[100dvh] z-[100] flex justify-center items-center ${
        open
          ? "bg-[#00000080]"
          : "bg-transparent select-none pointer-events-none"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[70%] h-[90%] bg-[white] text-[#5C5C5C] px-8 py-6 flex flex-col gap-[13px] shrink-0 transition-all duration-200 ease-in-out ${
          open
            ? "opacity-100 blur-0"
            : "opacity-0 blur-sm scale-110 select-none pointer-events-none"
        }`}
      >
        <div className="w-full flex justify-between items-center">
          <div className="font-semibold text-[16px] leading-[18px] flex flex-row items-center gap-1">
            <MdHelpOutline className="text-[20px]" />
            Help Guide
          </div>
          <button
            onClick={() => {
              setOpen(false);
            }}
            className="transition-all duration-200 ease-in-out hover:rotate-180"
          >
            <MdClose className="test=[20px]" />
          </button>
        </div>

        <div className="flex-grow overflow-auto w-full flex flex-row box-content border border-[#E9E8E8]">
          <div className="w-[270px] h-full flex flex-col border-r border-[#E9E8E8] shrink-0">
            <div className="w-full h-[37px] shrink-0 font-semibold text-[#7D7D7D] text-[11px] uppercase flex flex-row items-center gap-1 border-b border-[#E9E8E8] p-2">
              <MdList className="text-[20px]" />
              Index
            </div>
            <div className="flex-grow overflow-y-auto overflow-x-clip flex flex-col w-full">
              <div className="w-full relative flex flex-row items-center px-2 py-1">
                <MdOutlineSearch className="absolute left-4 text-[20px] text-[#DDDDDC] select-none pointer-events-none" />
                <input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  placeholder="Search..."
                  className="h-[28px] w-full pl-8 py-1 pr-1 placeholder:text-[#DDDDDC] text-[13px] outline-none border-[#DDDDDC] border"
                />
                {search !== "" && (
                  <MdClose
                    className="absolute right-4 text-[20px] text-[#818181] select-none cursor-pointer hover:text-[#3d3d3d]"
                    onClick={() => {
                      setSearch("");
                    }}
                  />
                )}
              </div>
              <div className="flex-grow w-full overflow-y-auto overflow-x-clip flex flex-col">
                {data
                  .filter((item) => item.sections.length > 0)
                  .filter((item) => {
                    if (search.trim() === "") return true;
                    return (
                      item.chapterTitle
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      item.sections
                        .map(
                          (section) =>
                            section.sectionTitle
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            section.richText
                              .toLowerCase()
                              .includes(search.toLowerCase())
                        )
                        .includes(true)
                    );
                  })
                  .map((chapter) => (
                    <div
                      className="flex flex-col gap-0 select-none ani"
                      key={chapter.id}
                    >
                      <div
                        key={chapter.id + " - " + chapter.chapterTitle}
                        title={
                          "id- " +
                          chapter.id +
                          "\nchapter title- " +
                          chapter.chapterTitle
                        }
                        onClick={() => {
                          const newData = data.map((item) => {
                            if (item.id === chapter.id) {
                              if (item.collapsed)
                                chapterLog({ chapter: chapter.chapterTitle });
                              item.collapsed = !item.collapsed;
                            }
                            return item;
                          });
                          setData([...newData]);
                        }}
                        className={`cursor-pointer px-2 hover:bg-bgHoverGrey w-full flex flex-row gap-2 justify-between items-start h-max py-[5.5px] border-b border-borderPrimary pr-2 ani`}
                      >
                        <div className="overflow-x-auto flex flex-row items-start gap-2">
                          <img
                            className="w-5 h-5 object-contain shrink-0 mt-[2px]"
                            src={chapter.chapterIconUrl || "/imageNotFound.png"}
                            alt="/imageNotFound.png"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/imageNotFound.png";
                            }}
                          />
                          <p className="text-[13px] text-textGrey text-left">
                            {chapter.chapterTitle}
                          </p>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <div className="text-[12px] text-[#7c7c7c]">
                            {chapter.collapsed ? (
                              <MdOutlineAdd className="text-[20px] shrink-0" />
                            ) : (
                              <MdOutlineRemove className="text-[20px] shrink-0" />
                            )}
                          </div>
                        </div>
                      </div>
                      {chapter.sections
                        .filter((section) => {
                          if (search.trim() === "") return true;
                          return (
                            section.sectionTitle
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            section.richText
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          );
                        })
                        .map((section) => (
                          <div
                            className="flex flex-col ani gap-0"
                            key={section.id}
                          >
                            <div
                              key={section.id + " - " + section.sectionTitle}
                              title={
                                "id- " +
                                section.id +
                                "\nsection title- " +
                                section.sectionTitle
                              }
                              onClick={() => {
                                sectionLog({
                                  chapter: chapter.chapterTitle,
                                  section: section.sectionTitle,
                                });
                                scrollToSection(section.id);
                              }}
                              className={`cursor-pointer hover:bg-bgPrimaryLight w-full flex flex-row gap-2 justify-start items-start pr-2 pl-8 ani overflow-hidden ${
                                chapter.collapsed
                                  ? "h-0 border-b-0 border-transparent"
                                  : "h-max py-[5.5px] border-b border-borderPrimary"
                              }`}
                            >
                              <div className="overflow-x-auto flex flex-row items-start gap-2">
                                <p className="text-[13px] text-textBlack text-left">
                                  {section.sectionTitle}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex-grow overflow-auto h-full flex flex-col">
            <div className="w-full h-[37px] text-[#5C5C5C] shrink-0 font-semibold text-[11px] uppercase flex flex-row items-center gap-2 border-b border-[#E9E8E8] p-2">
              <img
                className="w-[20px] h-[20px] self-start"
                src={
                  "https://lobbystore.blob.core.windows.net/lobby-adminpanel/icon-park-outline_add-subset.png"
                }
                alt="Invalid Icon"
              />
              Section Breakdown
            </div>
            <div className="flex-grow overflow-y-auto overflow-x-clip flex flex-col px-8 py-4 gap-2">
              {data.map((chapter) =>
                chapter.sections.map((section) => (
                  <div
                    key={section.id}
                    ref={(el) => (sectionRefs.current[section.id] = el)}
                    className="mb-8"
                  >
                    <div className="w-full h-max text-[#333333] text-left shrink-0 font-semibold text-[15px] flex flex-row items-start gap-2">
                      {section.sectionTitle}
                    </div>
                    {section.imageUrl && section.imageUrl.trim() !== "" && (
                      <>
                        <img
                          src={section.imageUrl}
                          className="w-full h-auto mt-4"
                          alt="SectionImg"
                        />
                        {section.imageLabel && (
                          <p className="w-full text-center text-[11px] uppercase text-[#5C5C5C] mt-2">
                            {section.imageLabel}
                          </p>
                        )}
                      </>
                    )}
                    <div className="ql-container ql-snow w-full ql-container-override h-max mt-4">
                      <div
                        className="ql-editor ql-editor-override h-max text-[13px]"
                        dangerouslySetInnerHTML={{
                          __html: section.richText || "<p></p>",
                        }}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
