// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo } from "react";
import NewDashboardBox from "../tooltip/NewDashboardBox";

const DashboardListBar = ({ dashBoardCategories, afterLandDashboard }) => {
  return (
    <div
      className="flex translate-x-0 justify-between  transition-all duration-700 ease-in-out flex-col z-50  h-screen   bg-[#282828] px-2 pt-2"
      style={{ fontFamily: "Roboto", fontSize: "12px" }}
    >
      <div className="h-full overflow-y-auto">
        {dashBoardCategories?.map((d) => (
          <div className={`flex flex-col`} key={d?._id}>
            <div
              className={`text-[#6d6565]  border-b border-[#5c5c5c] py-2   Roboto text-xs uppercase text-left `}
              style={{ fontSize: "12px" }}
            >
              {d?.label}
            </div>
            <div className="scrollable-element flex flex-col gap-4  py-2">
              {d?.dashboardIds?.map((dashboard) => (
                <NewDashboardBox
                  key={dashboard?._id}
                  afterLandDashboard={afterLandDashboard}
                  dashboard={dashboard}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(DashboardListBar);
