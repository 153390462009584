// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import Marquee from "react-fast-marquee";
import { memo } from "react";
import { getSession } from "../../utils/sessionstore";
import { FiExternalLink } from "react-icons/fi";
import { useSelector } from "react-redux";
import { userLogsAPI } from "../../services/session.service";

const NewsBar = () => {
  const { newsinfo } = useSelector((state) => ({ newsinfo: state.newsinfo }));

  function handleNewsClick(e, url, heading) {
    e.preventDefault();
    window.open(url);
    callUserLogAPI(heading);
  }
  const { userDetails, clientname } = useSelector((state) => ({
    clientname: state.clientname,
    userDetails: state.userDetails,
  }));
  const sessionId = getSession("session_id");
  async function callUserLogAPI(title) {
    try {
      const logresponse = userLogsAPI(
        "NEWS",
        newsinfo?.standarddashboardId?.standardName,
        userDetails,
        clientname,
        title,
        "Viewed"
      );
      // console.log(logresponse, "banner click");
    } catch (err) {
      console.log(err.message);
    }
  }
  return (
    <div className="left-[45px] flex fixed h-8 bottom-0 py-2 right-0 le z-40 bg-sideBarBg text-[#ffffff]">
      <div className="flex text-[#ffffff] Roboto text-base">
        <p className="px-2 Roboto text-xs">News</p>

        <Marquee
          gradient={false}
          className="Roboto"
          speed={112}
          pauseOnClick={true}
          pauseOnHover={true}
        >
          <div className="flex items-center justify-between">
            {newsinfo?.map(({ newsinfoId }) => (
              <div
                key={newsinfoId?._id}
                className="mx-2 flex cursor-pointer Roboto text-sm "
                onClick={(e) =>
                  handleNewsClick(e, newsinfoId?.url, newsinfoId?.heading)
                }
              >
                <p className="Roboto text-sm">{newsinfoId?.heading}</p>
                <div className="">
                  <FiExternalLink className="mt-1 ml-1" />
                </div>
              </div>
            ))}
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default memo(NewsBar);
