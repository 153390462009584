// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { useEffect, useRef, useState } from "react";
import { FaStar } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdClose,
  MdOutlineNotifications,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  saveCampaignFeedback,
  updateDynamicCampaginUserMapAPI,
} from "../../services/campaign.service";
import { getSession } from "../../utils/sessionstore";
import { useDispatch } from "react-redux";
import { userLogsAPI } from "../../services/session.service";
import {
  hideSalesPopUp,
  setActiveDashboard,
  showSalesPopUp,
  showVideoPopup,
} from "../../store/auth/action";

const DynamicCampaign = ({
  showPopup,
  activeCampaign,
  onClose,
  ribbonDynamicCampaignList,
  onRibbonClick,
  queuePopupActive = false,
  setRefetchDynamicCampaigns,
  apiLoading = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = getSession("session_hwai");
  const { clientID } = useParams();
  const { id } = useParams(); // dashboard id
  const sessionId = getSession("session_id");
  const client_id = getSession("client_id");
  const requiredClientID = clientID || client_id;
  const requiredUserID = getSession("session_hwai");
  const { userDetails, clientname } = useSelector((state) => ({
    clientname: state.clientname,
    userDetails: state.userDetails,
  }));

  async function callUserLogAPI(
    place,
    dashboardName,
    standardName,
    dashboardId,
    clickType
  ) {
    try {
      const logresponse = userLogsAPI(
        place,
        standardName,
        userDetails,
        clientname,
        dashboardName,
        clickType,
        requiredClientID,
        dashboardId
      );
      // console.log(logresponse, "banner click");
    } catch (err) {
      console.log(err.message);
    }
  }

  async function landDashboard(activedashboard) {
    //disabled dashboards with no sales content
    if (
      activedashboard?.url === "Greyed" ||
      (activedashboard?.url === "Disabled" && !activedashboard?.salesContentId)
    ) {
      callUserLogAPI(
        "DYNAMIC_CAMPAIGN",
        activedashboard?.label,
        activedashboard?.standarddashboardId?.standardName,
        activedashboard?._id,
        "Disabled"
      );
      onClose();
      setRefetchDynamicCampaigns((cur) => cur + 1);
      return;
    }

    //Open in new tab icon dashboards(In insights)
    if (activedashboard?.type === "Open in new tab") {
      callUserLogAPI(
        "DYNAMIC_CAMPAIGN",
        activedashboard?.label,
        activedashboard?.standarddashboardId?.standardName,
        activedashboard?._id,
        "Viewed"
      );
      window.open(activedashboard?.url, "_blank");
      onClose();
      setRefetchDynamicCampaigns((cur) => cur + 1);
      return;
    }

    //Open in new tab icon dashboards(Datacube)
    if (activedashboard?.type === "Data Cube") {
      const cubeUrl = activedashboard?.url.split("=")[0];
      window.open(cubeUrl + "=" + userDetails?.email);
      callUserLogAPI(
        "DYNAMIC_CAMPAIGN",
        activedashboard?.label,
        activedashboard?.standarddashboardId?.standardName,
        activedashboard?._id,
        "Viewed"
      );
      onClose();
      setRefetchDynamicCampaigns((cur) => cur + 1);
      return;
    }

    //Below conditions will first close the popup first
    dispatch(hideSalesPopUp());

    //Below conditions will first set the active dashboard
    dispatch(setActiveDashboard(activedashboard));

    //Videos recording
    if (activedashboard?.type == "video") {
      callUserLogAPI(
        "DYNAMIC_CAMPAIGN",
        activedashboard?.label,
        activedashboard?.standarddashboardId?.standardName,
        activedashboard?._id,
        "Viewed"
      );
      dispatch(showVideoPopup());
      onClose();
      setRefetchDynamicCampaigns((cur) => cur + 1);
      return;
    }

    //Disabled dashboard with salescontentId
    if (activedashboard?.url === "Disabled") {
      dispatch(showSalesPopUp("Locked icon on the side panel"));
      callUserLogAPI(
        "DYNAMIC_CAMPAIGN",
        activedashboard?.label,
        activedashboard?.standarddashboardId?.standardName,
        activedashboard?._id,
        "Disabled"
      );
      onClose();
      setRefetchDynamicCampaigns((cur) => cur + 1);
      return;
    }

    callUserLogAPI(
      "DYNAMIC_CAMPAIGN",
      activedashboard?.label,
      activedashboard?.standarddashboardId?.standardName,
      activedashboard?._id,
      "Viewed"
    );
    //Dashboard directly open
    if (userDetails?.permissions === "HWAI_ADMIN" && clientID !== undefined) {
      navigate(
        `/${clientID}/dashboard/${activedashboard?._id}/DYNAMIC_CAMPAIGN`
      );
    } else {
      navigate(`/dashboard/${activedashboard?._id}/DYNAMIC_CAMPAIGN`);
    }
    onClose();
    setRefetchDynamicCampaigns((cur) => cur + 1);
  }

  const updateDynamicCampaginUserMapForSubmitOrViewed = async (
    campaignItem
  ) => {
    // console.log("updateDynamicCampaginUserMap");
    try {
      const res = await updateDynamicCampaginUserMapAPI({
        id: campaignItem._id,
        viwedOnceOrSubmitted: true,
        apearanceCounter: campaignItem.apearanceCounter + 1,
        lastAppearedOn: new Date(),
      });
      // console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const updateDynamicCampaginUserMapForAppearace = async (campaignItem) => {
    // console.log("updateDynamicCampaginUserMap");
    try {
      const res = await updateDynamicCampaginUserMapAPI({
        id: campaignItem._id,
        viwedOnceOrSubmitted: campaignItem.viwedOnceOrSubmitted
          ? campaignItem.viwedOnceOrSubmitted
          : null,
        apearanceCounter: campaignItem.apearanceCounter + 1,
        lastAppearedOn: new Date(),
      });
      // console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (showPopup) {
      updateDynamicCampaginUserMapForAppearace(activeCampaign);
    }
  }, [showPopup]);

  return (
    <>
      <FeedbackForm
        showPopup={showPopup}
        activeCampaign={activeCampaign}
        onClose={onClose}
        onSubmitUpdate={() => {
          updateDynamicCampaginUserMapForSubmitOrViewed(activeCampaign);
          setRefetchDynamicCampaigns((cur) => cur + 1);
        }}
      />
      <PushNotification
        showPopup={showPopup}
        activeCampaign={activeCampaign}
        onClose={onClose}
        onViewUpdate={() =>
          updateDynamicCampaginUserMapForSubmitOrViewed(activeCampaign)
        }
        landDashboard={landDashboard}
      />
      <Reminder
        showPopup={showPopup}
        activeCampaign={activeCampaign}
        onClose={onClose}
        onViewUpdate={() =>
          updateDynamicCampaginUserMapForSubmitOrViewed(activeCampaign)
        }
        landDashboard={landDashboard}
      />
      <div
        className={`z-50 fixed -right-1 bottom-1/2 translate-y-1/2 flex flex-col gap-4 ani ${
          (queuePopupActive || showPopup || apiLoading) && "translate-x-full"
        }`}
      >
        {ribbonDynamicCampaignList.map((item, index) => (
          <button
            disabled={queuePopupActive || showPopup || apiLoading}
            key={index}
            onClick={() => onRibbonClick(item)}
            className="rounded-l-lg py-2 pl-1.5 pr-2.5 w-max bg-peach hover:-translate-x-1 ani active:translate-x-0.5"
          >
            <p className="uppercase text-[11px] leading-[16.5px] tracking-[0.55px] font-semibold text-white [writing-mode:vertical-lr] rotate-180">
              give feedback
            </p>
          </button>
        ))}
      </div>
    </>
  );
};

export default DynamicCampaign;

const FeedbackForm = ({
  showPopup,
  activeCampaign,
  onClose,
  onSubmitUpdate,
}) => {
  const { userDetails } = useSelector((state) => ({
    userDetails: state.userDetails,
  }));
  const { clientID } = useParams();
  const { id } = useParams(); // dashboard id
  const token = getSession("session_hwai");
  const client_id = getSession("client_id");
  const requiredClientID = clientID || client_id;
  const requiredUserID = getSession("session_hwai");

  const [form, setForm] = useState({
    openingQuestion: "",
    rating: 0,
    progressiveQuestions: [],
  });
  const [thanksView, setThanksView] = useState(false);

  async function saveFeedbackData() {
    try {
      const payload = {
        clientId: requiredClientID,
        userId: requiredUserID,
        campaignType: "DYNAMIC",
        dynamicCampaignId: activeCampaign.campaign._id,
        feedback: form,
      };
      await saveCampaignFeedback(payload);
      // console.log("feedback saved");
    } catch (err) {
      console.log(err);
    }
  }

  const onSubmit = () => {
    onSubmitUpdate();
    saveFeedbackData(form);
    // console.log("form submitted", form); // hit submit api here
    setThanksView(true);
  };

  const contentRef = useRef(null);
  const contentRef2 = useRef(null);
  const contentRef3 = useRef(null);
  const contentRef4 = useRef(null);

  useEffect(() => {
    setThanksView(false);
    setForm({
      rating: 0,
      openingQuestion:
        activeCampaign?.campaign?.popup?.feedbackForm?.openingQuestion,
      progressiveQuestions: [],
    });
    if (activeCampaign?.campaign?.popup?.feedbackForm?.progressiveForm) {
      setForm({
        rating: 0,
        openingQuestion:
          activeCampaign?.campaign?.popup?.feedbackForm?.openingQuestion,
        progressiveQuestions:
          activeCampaign?.campaign?.popup?.feedbackForm?.progressiveQuestions?.map(
            (item) => ({
              ...item,
              answer: null,
            })
          ),
      });
    }
  }, [activeCampaign]);

  useEffect(() => {
    if (form.rating !== 0 && form.rating !== 5) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
      contentRef.current.style.marginBottom = "16px";
    } else {
      contentRef.current.style.maxHeight = "0px";
      contentRef.current.style.marginBottom = "0px";
    }
  }, [form.rating]);

  useEffect(() => {
    if (thanksView) {
      contentRef2.current.style.maxHeight = "0px";
      contentRef.current.style.maxHeight = "0px";
      contentRef3.current.style.maxHeight = `${contentRef3.current.scrollHeight}px`;
      contentRef4.current.style.maxHeight = "0px";
      contentRef4.current.style.marginBottom = "0px";
      contentRef.current.style.marginBottom = "0px";
      contentRef3.current.style.marginBottom = "16px";
    } else {
      contentRef2.current.style.maxHeight = `${contentRef2.current.scrollHeight}px`;
      contentRef3.current.style.maxHeight = "0px";
      contentRef4.current.style.maxHeight = `${contentRef4.current.scrollHeight}px`;
      contentRef4.current.style.marginBottom = "16px";
      contentRef3.current.style.marginBottom = "0px";
      if (
        form.rating !== 0 &&
        form.rating !== 5 &&
        activeCampaign?.campaign?.popup?.feedbackForm?.progressiveForm
      ) {
        contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
        contentRef.current.style.marginBottom = "16px";
      } else {
        contentRef.current.style.maxHeight = "0px";
        contentRef.current.style.marginBottom = "0px";
      }
    }
  }, [thanksView]);

  useEffect(() => {
    if (thanksView) {
      setTimeout(() => {
        onClose();
      }, 3000);
    }
  }, [thanksView]);

  useEffect(() => {
    contentRef4.current.style.maxHeight = `${contentRef4.current.scrollHeight}px`;
    contentRef4.current.style.marginBottom = "16px";
  }, [activeCampaign]);

  return (
    <div
      onClick={() => {
        if (activeCampaign?.campaign?.popup?.closeable || thanksView) onClose();
      }}
      className={`fixed ani left-0 top-0 w-screen h-[100dvh] z-[40] flex justify-center items-center ${
        showPopup &&
        activeCampaign?.campaign?.popup?.popupType === "feedbackForm"
          ? "bg-[#00000080]"
          : "bg-transparent select-none pointer-events-none"
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`fixed select-none ani right-0 bottom-0 z-[110] flex flex-col gap-1 justify-center items-center shrink-0 px-6 py-14 ani ${
          showPopup &&
          activeCampaign?.campaign?.popup?.popupType === "feedbackForm"
            ? ""
            : "translate-x-full opacity-0"
        }`}
      >
        <div className="w-[348px] h-max px-6 py-5 shrink flex flex-col gap-0 justify-center items-center bg-white border border-borderPrimary [box-shadow:0px_4px_24px_0px_rgba(0,0,0,0.16);] ani">
          <span className="w-full flex flex-row items-start justify-between mb-4">
            <p className="text-[20px] font-[Roboto] font-normal leading-[30px] tracking-[1px] text-[#333]">
              {thanksView ? "" : "Rate your experience"}
            </p>
            {(activeCampaign?.campaign?.popup?.closeable || thanksView) && (
              <MdClose
                onClick={onClose}
                className="text-[24px] text-[#323232] cursor-pointer"
              />
            )}
          </span>
          <div
            ref={contentRef4}
            className="w-full flex flex-col gap-2 items-center shrink-0"
            style={{
              maxHeight: "0px",
              overflow: "hidden",
            }}
          >
            <p className="text-[13px] w-full text-left leading-[19.5px] tracking-[0.65px] text-[#333]">
              {activeCampaign?.campaign?.popup?.feedbackForm?.openingQuestion}
            </p>
            <StarsRating
              rating={form.rating}
              setRating={(r) => setForm({ ...form, rating: r })}
            />
          </div>
          <div
            ref={contentRef}
            className="w-full flex flex-col gap-4 ani"
            style={{ maxHeight: "0px", overflow: "hidden" }}
          >
            {activeCampaign?.campaign?.popup?.feedbackForm?.progressiveForm &&
              form.progressiveQuestions.map((item, index) => (
                <span
                  key={item._id || index}
                  className={`w-full flex flex-col gap-2 items-center`}
                >
                  {item.questionType === "descriptive" ? (
                    <>
                      <p className="text-[13px] w-full text-left leading-[19.5px] tracking-[0.65px] text-[#333]">
                        {item.questionTitle}
                      </p>
                      <textarea
                        value={item.answer || ""}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            progressiveQuestions: form.progressiveQuestions.map(
                              (i, ind) => {
                                if (ind === index) {
                                  return { ...i, answer: e.target.value };
                                }
                                return i;
                              }
                            ),
                          })
                        }
                        className="h-[60px] w-full resize-none placeholder:text-zinc-300 focus:outline-none px-2 py-1.5 text-[13px] bg-white border leading-[16px] tracking-[0.65px] border-[#dddddc] justify-start items-start gap-1 inline-flex"
                      />
                    </>
                  ) : item.questionType === "rating" ? (
                    <>
                      <>
                        <p className="text-[13px] w-full text-left leading-[19.5px] tracking-[0.65px] text-[#333]">
                          {item.questionTitle}
                        </p>
                        <StarsRating
                          rating={item.answer ? item.answer : 0}
                          setRating={(r) =>
                            setForm({
                              ...form,
                              progressiveQuestions:
                                form.progressiveQuestions.map((i, ind) => {
                                  if (ind === index) {
                                    return { ...i, answer: r };
                                  }
                                  return i;
                                }),
                            })
                          }
                        />
                      </>
                    </>
                  ) : item.questionType === "singleSelect" ? (
                    <>
                      <p className="text-[13px] w-full text-left leading-[19.5px] tracking-[0.65px] text-[#333]">
                        {item.questionTitle}
                      </p>
                      <span className="w-full flex flex-col gap-1">
                        {item.options.map((i, ind) => (
                          <label
                            key={i._id || ind}
                            className="w-full flex flex-row gap-1 items-center cursor-pointer group"
                            onClick={() => {
                              setForm({
                                ...form,
                                progressiveQuestions:
                                  form.progressiveQuestions.map((j, ind) => {
                                    if (ind === index) {
                                      return { ...j, answer: i.optionTitle };
                                    }
                                    return j;
                                  }),
                              });
                            }}
                          >
                            <div className="relative w-5 h-5 shrink-0">
                              <MdRadioButtonChecked
                                className={`absolute left-0 top-0 text-[20px] text-primary ani ${
                                  item.answer === i.optionTitle
                                    ? "opacity-100"
                                    : "opacity-0 group-hover:opacity-100 group-hover:text-textGrey"
                                } `}
                              />
                              <MdRadioButtonUnchecked
                                className={`absolute left-0 top-0 text-[20px] text-textGrey ani ${
                                  item.answer === i.optionTitle
                                    ? "opacity-0"
                                    : "opacity-100"
                                }`}
                              />
                            </div>
                            <p className="text-[13px] w-full text-left leading-[19.5px] tracking-[0.65px] text-[#333]">
                              {i.optionTitle}
                            </p>
                          </label>
                        ))}
                      </span>
                    </>
                  ) : item.questionType === "multiSelect" ? (
                    <>
                      <p className="text-[13px] w-full text-left leading-[19.5px] tracking-[0.65px] text-[#333]">
                        {item.questionTitle}
                      </p>
                      <span className="w-full flex flex-col gap-1">
                        {item.options.map((i, ind) => (
                          <label
                            key={i._id || ind}
                            className="w-full flex flex-row gap-1 items-center cursor-pointer group"
                            onClick={() => {
                              setForm({
                                ...form,
                                progressiveQuestions:
                                  form.progressiveQuestions.map((j, ind) => {
                                    if (ind === index) {
                                      return {
                                        ...j,
                                        answer: item.answer
                                          ? item.answer.includes(i.optionTitle)
                                            ? item.answer.filter(
                                                (item) => item !== i.optionTitle
                                              )
                                            : [...item.answer, i.optionTitle]
                                          : [i.optionTitle],
                                      };
                                    }
                                    return j;
                                  }),
                              });
                            }}
                          >
                            <div className="relative w-5 h-5 shrink-0">
                              <MdCheckBox
                                className={`absolute left-0 top-0 text-[20px] text-primary ani ${
                                  item.answer
                                    ? item.answer.includes(i.optionTitle)
                                      ? "opacity-100"
                                      : "opacity-0 group-hover:opacity-100 group-hover:text-textGrey"
                                    : "opacity-0 group-hover:opacity-100 group-hover:text-textGrey"
                                }`}
                              />
                              <MdCheckBoxOutlineBlank
                                className={`absolute left-0 top-0 text-[20px] text-textGrey ani ${
                                  item.answer
                                    ? item.answer.includes(i.optionTitle)
                                      ? "opacity-0"
                                      : "opacity-100"
                                    : "opacity-100"
                                }`}
                              />
                            </div>
                            <p className="text-[13px] w-full text-left leading-[19.5px] tracking-[0.65px] text-[#333]">
                              {i.optionTitle}
                            </p>
                          </label>
                        ))}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </span>
              ))}
          </div>
          <div
            ref={contentRef3}
            className="w-full flex flex-col gap-1 ani"
            style={{ maxHeight: "0px", overflow: "hidden" }}
          >
            <img
              alt="thankyou..."
              className="h-[70.5px] w-auto max-w-full object-contain"
              src={
                "https://lobbystore.blob.core.windows.net/img/thankyou_thumbsup.gif"
              }
            />
            <p className="text-[20px] text-black leading-[30px] tracking-[1px]">
              Thank you, {userDetails?.firstName || ""}
            </p>
            <p className="text-[13px] w-full leading-[19.5px] tracking-[0.65px] text-textGrey text-center">
              We appreciate your feedback. Your input helps us to improve and
              provide a better experience.
            </p>
          </div>
          <span className={"w-full flex flex-col gap-1"}>
            <button
              onClick={() => {
                thanksView ? onClose() : onSubmit();
              }}
              disabled={form.rating === 0}
              className={
                thanksView
                  ? "border border-borderPrimary py-1.5 px-2 bg-white text-textPrimary hover:text-primary hover:border-primary ani text-[13px] leading-4 tracking-[0.65px]"
                  : "border border-primary py-1.5 px-2 bg-primary text-white hover:bg-hoverPrimary hover:border-hoverPrimary ani text-[13px] leading-4 tracking-[0.65px] disabled:bg-[#BDA7C4] disabled:border-[#BDA7C4]"
              }
            >
              {thanksView ? "Close" : "Submit Feedback"}
            </button>
            <div
              ref={contentRef2}
              className="w-full flex flex-wrap items-center"
              style={{ maxHeight: "max-content", overflow: "hidden" }}
            >
              <p className="text-[13px] w-full leading-[19.5px] tracking-[0.65px] text-textGrey text-center">
                Your feedback does matter to us,&nbsp;
                <span className="text-[13px] leading-[19.5px] tracking-[0.65px] w-max text-black">
                  {userDetails?.firstName || ""}
                </span>
              </p>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

const PushNotification = ({
  showPopup,
  activeCampaign,
  onClose,
  onViewUpdate,
  landDashboard,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [viewed, setViewed] = useState(false);
  const slidesContainerRef = useRef(null);
  const slideRefs = useRef([]);

  const onLastSlideView = () => {
    onViewUpdate();
    // console.log("last slide viewed");
    setViewed(true);
  };

  const onVisit = () => {
    landDashboard(activeCampaign?.campaign?.redirectDashboard);
  };

  useEffect(() => {
    setCurrentSlide(0);
    setViewed(false);
  }, [activeCampaign]);

  useEffect(() => {
    if (
      currentSlide ===
      activeCampaign?.campaign?.popup?.pushNotification?.slides?.length - 1
    ) {
      onLastSlideView();
    }

    // Adjust the height of the slides container
    if (slidesContainerRef.current && slideRefs.current[currentSlide]) {
      const activeSlideHeight =
        slideRefs.current[currentSlide]?.scrollHeight || 0;
      slidesContainerRef.current.style.height = `${activeSlideHeight}px`;
    }
  }, [currentSlide, activeCampaign]);

  return (
    <div
      onClick={() => {
        if (
          activeCampaign?.campaign?.popup?.closeable ||
          currentSlide ===
            activeCampaign?.campaign?.popup?.reminder?.slides?.length - 1
        )
          onClose();
      }}
      className={`fixed ani left-0 top-0 w-screen h-[100dvh] z-[40] flex justify-center items-center ${
        showPopup &&
        activeCampaign?.campaign?.popup?.popupType === "pushNotification"
          ? "bg-[#00000080]"
          : "bg-transparent select-none pointer-events-none"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`fixed select-none ani z-[110] flex flex-col gap-1 justify-center items-center shrink-0 px-6 py-14 ani ${
          activeCampaign?.campaign?.popup?.pushNotification?.position ===
          "center"
            ? "top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            : "right-0 bottom-0"
        } ${
          showPopup &&
          activeCampaign?.campaign?.popup?.popupType === "pushNotification"
            ? ""
            : "translate-x-full translate-y-full opacity-0"
        }`}
      >
        <div className="w-[500px] h-max px-6 py-5 shrink-0 flex flex-col gap-4 justify-center items-center bg-white border border-borderPrimary [box-shadow:0px_4px_24px_0px_rgba(0,0,0,0.16);] ani">
          <span className="w-full flex flex-row items-start justify-between">
            <p className="text-[16px] font-[Roboto] font-semibold leading-[20px] tracking-[0.8px] text-[#333]">
              {activeCampaign?.campaign?.popup?.pushNotification?.popupHeader ||
                "Notification"}
            </p>
            {(activeCampaign?.campaign?.popup?.closeable ||
              currentSlide ===
                activeCampaign?.campaign?.popup?.pushNotification?.slides
                  ?.length -
                  1) && (
              <MdClose
                onClick={onClose}
                className="text-[20px] text-[#323232] cursor-pointer"
              />
            )}
          </span>
          <span
            ref={slidesContainerRef}
            className="flex-grow w-full overflow-hidden flex flex-col"
          >
            <div className="w-full h-max flex flex-row justify-center items-center overflow-x-clip">
              <div
                className="w-full h-max shrink-0 ani flex flex-row items-start"
                style={{ transform: `translateX(-${currentSlide * 100}%)` }}
              >
                {activeCampaign?.campaign?.popup?.pushNotification?.slides?.map(
                  (i, index) => (
                    <div
                      key={i._id || index}
                      ref={(el) => (slideRefs.current[index] = el)}
                      className="w-full h-max shrink-0 flex justify-start items-start px-2"
                    >
                      <div className="ql-container ql-snow w-full ql-container-override h-max ql-container-override2">
                        <div
                          className="ql-editor ql-editor-override h-max text-[13px]"
                          dangerouslySetInnerHTML={{
                            __html: i.richText || "<p></p>",
                          }}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </span>
          <span className="w-full flex flex-row items-start justify-between">
            <p className="text-[13px] text-textGrey leading-[19.5px] tracking-[0.65px]">
              {currentSlide + 1}&nbsp;of&nbsp;
              {
                activeCampaign?.campaign?.popup?.pushNotification?.slides
                  ?.length
              }
            </p>
            <span className="w-max flex flex-row items-center justify-end gap-[10px]">
              {activeCampaign?.campaign?.popup?.closeable &&
                currentSlide === 0 && (
                  <button
                    onClick={onClose}
                    className="border border-borderPrimary py-1.5 px-2 bg-white text-textPrimary hover:text-primary hover:border-primary ani text-[13px] leading-4 tracking-[0.65px]"
                  >
                    Skip
                  </button>
                )}
              {currentSlide !== 0 && (
                <button
                  onClick={() => {
                    if (currentSlide > 0) {
                      setCurrentSlide(currentSlide - 1);
                    }
                  }}
                  className="border border-borderPrimary py-1.5 px-2 bg-white text-textPrimary hover:text-primary hover:border-primary ani text-[13px] leading-4 tracking-[0.65px]"
                >
                  Previous
                </button>
              )}
              {currentSlide !==
                activeCampaign?.campaign?.popup?.pushNotification?.slides
                  ?.length -
                  1 && (
                <button
                  onClick={() => {
                    if (
                      currentSlide <
                      activeCampaign?.campaign?.popup?.pushNotification?.slides
                        ?.length -
                        1
                    ) {
                      setCurrentSlide(currentSlide + 1);
                    }
                  }}
                  className="border border-primary py-1.5 px-2 bg-primary text-white hover:bg-hoverPrimary hover:border-hovrerPrimary ani text-[13px] leading-4 tracking-[0.65px]"
                >
                  Next
                </button>
              )}
              {activeCampaign?.campaign?.popup?.pushNotification
                ?.dashboardRedirectEnabled &&
              !!activeCampaign?.campaign?.redirectDashboard &&
              currentSlide ===
                activeCampaign?.campaign?.popup?.pushNotification?.slides
                  ?.length -
                  1 ? (
                <button
                  onClick={onVisit}
                  className="border border-primary py-1.5 px-2 bg-primary text-white hover:bg-hoverPrimary hover:border-hovrerPrimary ani text-[13px] leading-4 tracking-[0.65px] flex flex-row items-center justify-center"
                >
                  Visit&nbsp;
                  <FiExternalLink className="text-white text-[13px]" />
                </button>
              ) : (
                <>
                  {currentSlide ===
                    activeCampaign?.campaign?.popup?.pushNotification?.slides
                      ?.length -
                      1 && (
                    <button
                      onClick={onClose}
                      className="border border-primary py-1.5 px-2 bg-primary text-white hover:bg-hoverPrimary hover:border-hovrerPrimary ani text-[13px] leading-4 tracking-[0.65px]"
                    >
                      Close
                    </button>
                  )}
                </>
              )}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

const Reminder = ({
  showPopup,
  activeCampaign,
  onClose,
  onViewUpdate,
  landDashboard,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [viewed, setViewed] = useState(false);
  const slidesContainerRef = useRef(null);
  const slideRefs = useRef([]);

  const onLastSlideView = () => {
    onViewUpdate();
    // console.log("last slide viewed");
    setViewed(true);
  };

  const onVisit = () => {
    landDashboard(activeCampaign?.campaign?.redirectDashboard);
  };

  useEffect(() => {
    setCurrentSlide(0);
    setViewed(false);
  }, [activeCampaign]);

  useEffect(() => {
    if (
      currentSlide ===
      activeCampaign?.campaign?.popup?.pushNotification?.slides?.length - 1
    ) {
      onLastSlideView();
    }

    // Adjust the height of the slides container
    if (slidesContainerRef.current && slideRefs.current[currentSlide]) {
      const activeSlideHeight =
        slideRefs.current[currentSlide]?.scrollHeight || 0;
      slidesContainerRef.current.style.height = `${activeSlideHeight}px`;
    }
  }, [currentSlide, activeCampaign]);
  return (
    <div
      onClick={() => {
        if (
          activeCampaign?.campaign?.popup?.closeable ||
          currentSlide ===
            activeCampaign?.campaign?.popup?.reminder?.slides?.length - 1
        )
          onClose();
      }}
      className={`fixed ani left-0 top-0 w-screen h-[100dvh] z-[40] flex justify-center items-center ${
        showPopup && activeCampaign?.campaign?.popup?.popupType === "reminder"
          ? "bg-[#00000080]"
          : "bg-transparent select-none pointer-events-none"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`fixed select-none ani right-0 top-0 z-[110] flex flex-col gap-1 justify-center items-center shrink-0 px-6 py-[72px] ani ${
          showPopup && activeCampaign?.campaign?.popup?.popupType === "reminder"
            ? ""
            : "translate-x-full opacity-0"
        }`}
      >
        <div className="w-[348px] h-max px-8 py-6 shrink-0 flex flex-col gap-4 justify-center items-center bg-white border border-borderPrimary [box-shadow:0px_4px_24px_0px_rgba(0,0,0,0.16);] ani">
          <span className="w-full flex flex-row items-start justify-between">
            <p className="text-[16px] font-[Roboto] font-semibold leading-[20px] tracking-[0.8px] text-[#333] flex flex-row items-center">
              <MdOutlineNotifications className="text-[20px] text-[#333]" />
              &nbsp;Reminder
            </p>
            {(activeCampaign?.campaign?.popup?.closeable ||
              currentSlide ===
                activeCampaign?.campaign?.popup?.reminder?.slides?.length -
                  1) && (
              <MdClose
                onClick={onClose}
                className="text-[20px] text-[#323232] cursor-pointer"
              />
            )}
          </span>
          <span
            ref={slidesContainerRef}
            className="flex-grow w-full overflow-hidden flex flex-col"
          >
            <div className="w-full h-max flex flex-row justify-center items-center overflow-x-clip">
              <div
                className="w-full h-max shrink-0 ani flex flex-row items-start"
                style={{ transform: `translateX(-${currentSlide * 100}%)` }}
              >
                {activeCampaign?.campaign?.popup?.reminder?.slides?.map(
                  (i, index) => (
                    <div
                      key={i._id || index}
                      ref={(el) => (slideRefs.current[index] = el)}
                      className="w-full h-max shrink-0 flex justify-start items-start px-2"
                    >
                      <div className="ql-container ql-snow w-full ql-container-override h-max ql-container-override2">
                        <div
                          className="ql-editor ql-editor-override h-max text-[13px]"
                          dangerouslySetInnerHTML={{
                            __html: i.richText || "<p></p>",
                          }}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </span>
          <span className="w-full flex flex-row items-start justify-between">
            <p className="text-[13px] text-textGrey leading-[19.5px] tracking-[0.65px]">
              {currentSlide + 1}&nbsp;of&nbsp;
              {activeCampaign?.campaign?.popup?.reminder?.slides?.length}
            </p>
            <span className="w-max flex flex-row items-center justify-end gap-[10px]">
              {activeCampaign?.campaign?.popup?.closeable &&
                currentSlide === 0 && (
                  <button
                    onClick={onClose}
                    className="border border-borderPrimary py-1.5 px-2 bg-white text-textPrimary hover:text-primary hover:border-primary ani text-[13px] leading-4 tracking-[0.65px]"
                  >
                    Skip
                  </button>
                )}
              {currentSlide !== 0 && (
                <button
                  onClick={() => {
                    if (currentSlide > 0) {
                      setCurrentSlide(currentSlide - 1);
                    }
                  }}
                  className="border border-borderPrimary py-1.5 px-2 bg-white text-textPrimary hover:text-primary hover:border-primary ani text-[13px] leading-4 tracking-[0.65px]"
                >
                  Previous
                </button>
              )}
              {currentSlide !==
                activeCampaign?.campaign?.popup?.reminder?.slides?.length -
                  1 && (
                <button
                  onClick={() => {
                    if (
                      currentSlide <
                      activeCampaign?.campaign?.popup?.reminder?.slides
                        ?.length -
                        1
                    ) {
                      setCurrentSlide(currentSlide + 1);
                    }
                  }}
                  className="border border-primary py-1.5 px-2 bg-primary text-white hover:bg-hoverPrimary hover:border-hovrerPrimary ani text-[13px] leading-4 tracking-[0.65px]"
                >
                  Next
                </button>
              )}
              {activeCampaign?.campaign?.popup?.reminder
                ?.dashboardRedirectEnabled &&
              !!activeCampaign?.campaign?.redirectDashboard &&
              currentSlide ===
                activeCampaign?.campaign?.popup?.reminder?.slides?.length -
                  1 ? (
                <button
                  onClick={onVisit}
                  className="border border-primary py-1.5 px-2 bg-primary text-white hover:bg-hoverPrimary hover:border-hovrerPrimary ani text-[13px] leading-4 tracking-[0.65px] flex flex-row items-center justify-center"
                >
                  Visit&nbsp;
                  <FiExternalLink className="text-white text-[13px]" />
                </button>
              ) : (
                <>
                  {currentSlide ===
                    activeCampaign?.campaign?.popup?.reminder?.slides?.length -
                      1 && (
                    <button
                      onClick={onClose}
                      className="border border-primary py-1.5 px-2 bg-primary text-white hover:bg-hoverPrimary hover:border-hovrerPrimary ani text-[13px] leading-4 tracking-[0.65px]"
                    >
                      Close
                    </button>
                  )}
                </>
              )}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

const StarsRating = ({ rating, setRating }) => {
  const [hoverRating, setHoverRating] = useState(0);

  return (
    <div
      onMouseLeave={() => setHoverRating(0)}
      className="flex flex-row shrink-0 gap-0"
    >
      {[1, 2, 3, 4, 5].map((star) => (
        <button
          key={star} // Unique key for each star
          onClick={() => setRating(star)}
          onMouseEnter={() => setHoverRating(star)}
          onMouseLeave={() => setHoverRating(0)}
          className="group px-[5.41px]"
        >
          <FaStar
            className={`ani group-active:scale-90 group-hover:scale-110 text-[24px] ${
              star <= (hoverRating || rating)
                ? "text-[#FFD029]"
                : "text-[#E9E8E8]"
            }`}
          />
        </button>
      ))}
    </div>
  );
};

export function filterCampaignsByRangeDate(campaignList) {
  const currentDate = new Date();

  return campaignList.filter((item) => {
    const campaign = item.campaign;
    const startDate = new Date(campaign.startDate);
    const endDate = new Date(campaign.endDate);

    const isInDateRange = currentDate >= startDate && currentDate <= endDate;

    return isInDateRange;
  });
}

export function filterCampaignsByLastAppearedDate(campaignList) {
  const currentDate = new Date();

  return campaignList.filter((item) => {
    const lastAppearedOn = item.lastAppearedOn
      ? new Date(item.lastAppearedOn)
      : null;
    const isMoreThanOneDay = lastAppearedOn
      ? currentDate - lastAppearedOn > 1000 * 60 * 60 * 24 // 1day
      : true;

    return isMoreThanOneDay;
  });
}

export function filterCampaignsByPausedOrNot(campaignList) {
  return campaignList.filter((item) => {
    const campaign = item.campaign;

    const isPaused = campaign.paused ? campaign.paused : false;
    return !isPaused;
  });
}

export function filterCampaignsByEnvironment(
  campaignList,
  isDashboard,
  dashboardId
) {
  return campaignList.filter((item) => {
    const campaign = item.campaign;

    const eligible = isDashboard
      ? campaign.dashboard
        ? campaign.dashboard === dashboardId
        : true
      : campaign.environment === "LOBBY";
    return eligible;
  });
}

export function filterCampaignsByAppearancesLeft(campaignList) {
  return campaignList.filter((item) => {
    const campaign = item.campaign;

    const appearancesLeft = campaign.maximumAppearances - item.apearanceCounter;
    return appearancesLeft > 0;
  });
}

export function filterCampaignsByViewedOrSubmitted(campaignList) {
  return campaignList.filter((item) => {
    const campaign = item.campaign;
    const popup = item.campaign.popup;

    const viewedOrSubmitted =
      popup.popupType === "feedbackForm"
        ? !!item.viwedOnceOrSubmitted
        : campaign.stopOnView
        ? !!item.viwedOnceOrSubmitted
        : false;
    return !viewedOrSubmitted;
  });
}

export function sortCampaignsByTriggerDelay(campaignList) {
  return campaignList.sort((a, b) => {
    return a.campaign.triggerDelay - b.campaign.triggerDelay;
  });
}

export function filterCampaignsByRibbon(campaignList) {
  return campaignList.filter((item) => {
    const campaign = item.campaign;
    return campaign.appearAsRibbon && campaign.environment === "DASHBOARD";
  });
}

// {
//     "_id": "66f716979ad81a192c3652a6",
//     "client": "66d958fd740be6a4e83a620b",
//     "dashboard": "66dab1ecac88a4b883ef65c2",
//     "campaign": {
//         "_id": "66f716979ad81a192c3652a4",
//         "campaignTitle": "Dashboard - Feedback - Type 1",
//         "popup": {
//             "_id": "66f700a64ad16193cd8d96c3",
//             "popupType": "feedbackForm",
//             "popupTitle": "Feedback Form - With progressive Questions - Only single select",
//             "closeable": true,
//             "feedbackForm": {
//                 "progressiveForm": true,
//                 "openingQuestion": "How do you like our new feature?",
//                 "progressiveQuestions": [
//                     {
//                         "questionTitle": "This is single select question",
//                         "questionType": "singleSelect",
//                         "options": [
//                             {
//                                 "optionTitle": "Option 1 - Lorem",
//                                 "_id": "66f700a64ad16193cd8d96c6"
//                             },
//                             {
//                                 "optionTitle": "Option 2 - Ipsum",
//                                 "_id": "66f700a64ad16193cd8d96c7"
//                             },
//                             {
//                                 "optionTitle": "Option 3 - Nisto",
//                                 "_id": "66f700a64ad16193cd8d96c8"
//                             }
//                         ],
//                         "_id": "66f700a64ad16193cd8d96c5"
//                     }
//                 ],
//                 "_id": "66f700a64ad16193cd8d96c4"
//             },
//             "pushNotification": {
//                 "position": "bottomRight",
//                 "slides": [
//                     {
//                         "richText": "",
//                         "_id": "66f700a64ad16193cd8d96ca"
//                     }
//                 ],
//                 "dashboardRedirectEnabled": false,
//                 "_id": "66f700a64ad16193cd8d96c9"
//             },
//             "reminder": {
//                 "slides": [
//                     {
//                         "richText": "",
//                         "_id": "66f700a64ad16193cd8d96cc"
//                     }
//                 ],
//                 "dashboardRedirectEnabled": false,
//                 "_id": "66f700a64ad16193cd8d96cb"
//             },
//             "__v": 0
//         },
//         "environment": "DASHBOARD",
//         "dashboard": "66dab1ecac88a4b883ef65c2",
//         "client": "66d958fd740be6a4e83a620b",
//         "startDate": "2024-09-26T18:[40]:00.000Z",
//         "endDate": "2024-09-29T18:[40]:00.000Z",
//         "appearAsRibbon": false,
//         "triggerDelay": 0,
//         "maximumAppearances": 1,
//         "stopOnView": false,
//         "paused": false,
//         "redirectDashboard": null,
//         "__v": 0
//     },
//     "user": "66f64f3ed7d06ca969410558",
//     "viwedOnceOrSubmitted": null,
//     "apearanceCounter": 0,
//     "lastAppearedOn": null,
//     "__v": 0
// }
