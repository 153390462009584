// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo } from "react";

const TransparentButton = ({ text, onclick }) => {
  return (
    <button
      className="px-4 py-1 bg-white border border-zinc-300 justify-center items-center gap-1 hover:bg-zinc-100"
      onClick={onclick}
    >
      <div className="text-[#7D528B] text-[13px] leading-tight tracking-wide">
        {text}
      </div>
    </button>
  );
};

export default memo(TransparentButton);
